import storage from 'store'
import { getLoginUser, getUserCenterUserInfo, logout } from '@/api/user'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { queryMessage } from '@/api/work'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    menu: [],
    noReadMsgCount: 0,
    detail: {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      info.uid = info.username
      state.info = info
    },
    SET_MEMU: (state, menu) => {
      state.menu = menu
    },
    SET_NO_READ_MSG_COUNT (state, key) {
      state.noReadMsgCount = key
    },
    SET_USER_DETAIL (state, detail) {
      state.detail = detail
    }
  },

  actions: {
    // 初始化登录用户信息
    initLoginUser ({ commit }) {
      return new Promise((resolve, reject) => {
        getLoginUser().then(userResult => {
          const userInfo = userResult.data
          userInfo.uid = userInfo.username
          // 获取有权限的菜单
          // storage.set('userCode', userInfo.uid)
          // storage.set('tenantId', userInfo.tenantId)
          localStorage.setItem('userCode', userInfo.username)
          localStorage.setItem('userNo', userInfo.username)
          localStorage.setItem('userName', userInfo.nickName || userInfo.realName)
          localStorage.setItem('uniqueNo', userInfo.uniqueNo)
          localStorage.setItem('userID', userInfo.id)
          localStorage.setItem('tenantId', '10001')
          commit('SET_INFO', userInfo)
          commit('SET_NAME', { name: userInfo.realName })
          commit('SET_AVATAR', 'http://wps.talkweb.com.cn/file/201911/15/f331a6a29cb8484f88647e9dfddb6800.png')

          console.log('============>>getLoginUser  ', localStorage.getItem('uniqueNo'))
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          console.log()
        })
      })
    },

    // 更新未读信息条数
    updateNoReadMsgCount ({ commit, state }) {
      return queryMessage({
        userCode: state.info.uid,
        pageIndex: 1,
        pageSize: 1,
        status: 'noread'
      }).then((res) => {
        if (String(res.code) === '0') {
          commit('SET_NO_READ_MSG_COUNT', res.total)
        }
      })
    },
    // 获取用户详情
    getUserDetail ({ commit, state }, callback) {
      getUserCenterUserInfo(state.info.uniqueNo).then((data) => {
        if (data) {
          commit('SET_USER_DETAIL', data)
          if (callback instanceof Function) {
            callback(data)
          }
        }
      })
    }
  }
}

export default user
