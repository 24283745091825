import request from '@/utils/request'

const HAECHI = process.env.VUE_APP_API_HAECHI
const PROCESS = process.env.VUE_APP_PROCESS_API_PREFIX

const Api = {
  queryDraft: HAECHI + '/v1/workorder/draft/query', // 草稿页面数据查询
  updateDraStatus: HAECHI + '/v1/workorder/draft/deletelist', // 草稿页面状态批量修改
  queryApply: HAECHI + '/v1/workorder/apply/getuserapply', // 申请页面数据查询
  queryAllProcessname: HAECHI + '/v1/process/getnamefortask', // 查询所有的有效流程
  queryMessage: HAECHI + '/v1/message/conditionquery', // 根据条件查询用户消息
  updateMessage: HAECHI + '/v1/message/changeStatusBatch', // 单个-批量更新消息状态
  queryUndoStatus: PROCESS + '/v1/wftask/judgeTaskWithdraw', // 获取申请页面单据状态
  queryTaskDefinitionKey: PROCESS + '/v1/wfmanagement/taskdefinitionkeyforview' // 通过任务ID获取环节ID
}

export function queryDraft (params) {
  return request({
    url: Api.queryDraft,
    method: 'post',
    data: params
  })
}

export function updateDraStatus (params) {
  return request({
    url: Api.updateDraStatus,
    method: 'post',
    data: params
  })
}

export function queryApply (params) {
  return request({
    url: Api.queryApply,
    method: 'post',
    data: params
  })
}

export function queryAllProcessname () {
  return request({
    url: Api.queryAllProcessname,
    method: 'get'
  })
}

export function queryMessage (params) {
  return request({
    url: Api.queryMessage,
    method: 'post',
    data: params
  })
}

export function updateMessage (params) {
  return request({
    url: Api.updateMessage,
    method: 'post',
    data: params
  })
}

// export function queryUndoStatus () {
//   return request({
//     url: Api.queryUndoStatus,
//     method: 'get',
//     params: {
//       processInstanceId: '3721208'
//     }
//   })
// }
export function queryUndoStatus (params) {
  return request({
    url: Api.queryUndoStatus,
    method: 'get',
    params: params
  })
}

export function queryTaskDefinitionKey (params) {
  return request({
    url: Api.queryTaskDefinitionKey,
    method: 'get',
    params: params
  })
}
