import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import { VueAxios, axios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import backendModules from '@twit/backend-modules'
import VueCompositionAPI from '@vue/composition-api'
import './core/lazy_use' // use lazy load components
// import './permission' // permission control
import md5 from 'js-md5'
import initAuth from './init-auth'
import VueApp from './App.js'
import './utils/filter' // global filter
import './global.less' // global style
import '@/components/NProgress/nprogress.less' // progress bar custom style
import 'ant-design-vue/dist/antd.less'
import '@twit/backend-modules/lib/themes/twit.css'
// 解决子应用official无法使用ueditor编辑器的问题
import VueUeditorWrap from 'vue-ueditor-wrap'
window.VueUeditorWrap = VueUeditorWrap
// window.onerror = (msg, url, line) => {
//   console.log(msg, url, line)
//   return true
// }

// window.addEventListener('error', (event) => {
//   console.log(event)
//   event.preventDefault()
// }, true)

// Vue.config.warnHandler = (msg, vm, trace) => {
//   console.log(msg, vm, trace)
// }

Vue.config.productionTip = false

initAuth()
Vue.use(VueAxios)
Vue.use(backendModules)
Vue.use(VueCompositionAPI)
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.prototype.$ajax = axios
Vue.prototype.$md5 = md5

window.umi_plugin_ant_themeVar = themePluginConfig.theme

VueApp.$mount('#main-app')
