import router from './router'
import store from './store'
// import utils from '@twit/utils'

// function getPathSession (url, name) {
//   if (url.indexOf(name) >= 0) {
//     const temp1 = url.split('SESSION=')
//     return temp1[1]
//   } else {
//     return false
//   }
// }

function initRouters (to, from, next) {
  if (store.getters.addRouters.length !== 0) {
    return
  }
  store.dispatch('initLoginUser').then((perssion) => {
    store.dispatch('GenerateRoutes', { perssion }).then(() => {
      // 根据roles权限生成可访问的路由表,动态添加可访问路由表
      router.addRoutes(store.getters.addRouters)
    })
  })
}

export default function initAuth () {
  // const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiIxNjc2MDgzNTU5MzE1NTg3MDc0Iiwic3ViIjoiMjE5NDkiLCJhdWQiOiJ0ZW5hbnQiLCJ1bmFtZSI6IjIxOTQ5IiwiYXBwSWQiOiJkZWZhdWx0QXBwSWQiLCJpc3MiOiJpZGFhcyIsInRlbmFudElkIjoiZGVmYXVsdFRlbmFudElkIiwicm5kIjoiYjc2Nzg4ZmoiLCJleHAiOjE2OTExMTY1ODcsImlhdCI6MTY5MDUxMTc4N30.cgY8nbpR2337_j5J-2MlV0OpxDSNltVPSDQgOSVFlZ0'
  // localStorage.setItem('TOKEN_KEY', token)
  if (localStorage.getItem('TOKEN_KEY')) {
    initRouters()
  } else {
    window.open('/idaas/login', '_self')
  }
}
