import { constantRouterMap } from '@/config/router.config'

// const RouteView = {
//   name: 'RouteView',
//   render: (h) => h('router-view')
// }

// function initTreeNodes (menus) {
//   const nodes = []
//   for (const item of menus) {
//     const { id, pid, menuCode, menuPath, isShowAppList, menuName, menuIcon } = item
//     nodes.push({
//       id: id,
//       pid: pid,
//       name: menuCode,
//       path: menuPath,
//       hidden: isShowAppList === 'N',
//       component: routerMap[menuCode] || RouteView,
//       meta: {
//         title: menuName,
//         icon: menuIcon || undefined,
//         keepAlive: true,
//         target: menuPath ? (menuPath.startsWith('http') ? menuPath : '') : ''
//       }
//     })
//   }
//   return nodes
// }

// function createRouterTree (menus) {
//   const nodes = initTreeNodes(menus)
//   const treeNodes = []
//   for (const menu1 of nodes) {
//     const children = []
//     for (const menu2 of nodes) {
//       if (menu1.id + '' === menu2.pid + '') {
//         children.push(menu2)
//       }
//     }
//     if (children.length > 0) {
//       menu1.children = children
//     }
//     treeNodes.push(menu1)
//   }

//   const list = []
//   for (const menu1 of treeNodes) {
//     var isRoot = true
//     for (const menu2 of treeNodes) {
//       if (menu1.pid + '' === menu2.id + '') {
//         isRoot = false
//         break
//       }
//     }
//     if (isRoot) {
//         list.push(menu1)
//     }
//   }

//   for (const item of list) {
//     rootRouter.children.push(item)
//   }
//   return [rootRouter]
// }

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
    addRouterList: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        // const routers = createRouterTree(data.perssion)
        const routers = []
        commit('SET_ROUTERS', routers)
        resolve(routers)
      })
    }
  }
}

export default permission
