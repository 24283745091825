const appList = [
  {
    name: 'PBC',
    title: '公共中心',
    port: '8001'
  },
  {
    name: 'HR',
    title: '人力资源管理',
    port: '8002'
  },
  {
    name: 'WOS',
    title: '工单管理',
    port: '8003'
  },
  {
    name: 'PMS',
    title: '交付管理',
    port: '8005'
  },
  {
    name: 'BPM',
    title: '流程系统',
    port: '8006'
  },
  {
    name: 'FCR',
    title: '费控',
    port: '8007'
  },
  {
    name: 'PMSP',
    title: '交付运营流程管理',
    port: '8008'
  },
  {
    name: 'WPASS',
    title: '拓维园区产品',
    port: '8009'
  },
  {
    name: 'WHS',
    title: '仓库管理',
    port: '8010'
  },
  {
    name: 'ADMINS',
    title: '行政综合',
    port: '8011'
  },
  {
    name: 'ATTENDANCE',
    title: '考勤管理',
    port: '8012'
  },
  {
    name: 'OFFICIAL',
    title: '官网管理后台',
    port: '8013'
  },
  {
    name: 'FCB',
    title: '财务预算后台',
    port: '8015'
  },
  {
    name: 'REPORTS',
    title: '报表后台',
    port: '8016'
  },
  {
    name: 'KPIS',
    title: '绩效管理',
    port: '8017'
  },
  {
    name: 'FCC',
    title: '财务配置',
    port: '8018'
  },
  {
    name: 'ATTENDANCE2',
    title: '考勤管理2',
    port: '8019'
  },
  {
    name: 'QUALIFICATIONS',
    title: '任职资格',
    port: '8020'
  },
  {
    name: 'USERCENTER',
    title: '用户中心',
    port: '8021'
  },
  {
    name: 'MINIPROGRAM',
    title: '小程序管理后台',
    port: '8022'
  },
  {
    name: 'DATACENTER',
    title: '大数据中心',
    port: '8023'
  },
  {
    name: 'CRM',
    title: '客户运营管理',
    port: '8024'
  },
  {
    name: 'AUTH',
    title: '鉴权中心',
    port: '8025'
  },
  {
    name: 'FCQU',
    title: '财务资质',
    port: '8026'
  },
  {
    name: 'SRM',
    title: '供应链管理',
    port: '8027'
  },
  {
    name: 'CANTEEN',
    title: '食堂管理',
    port: '8028'
  },
  {
    name: 'TAXMG',
    title: '进项发票管理',
    port: '8029'
  },
  {
    name: 'IMAGING',
    title: '影像管理',
    port: '8030'
  },
  {
    name: 'BUDGET',
    title: '预算管理',
    port: '8031'
  },
  {
    name: 'MDM',
    title: '主数据管理',
    port: '8032'
  },
  {
    name: 'CONTROLSERVER',
    title: '差旅平台',
    port: '8033'
  },
  {
    name: 'SALES',
    title: '销售管理',
    port: '8034'
  },
  {
    name: 'EXPENSE',
    title: '报账平台',
    port: '8035'
  },
  {
    name: 'INVOICE',
    title: '乐企平台',
    port: '8036'
  },
  {
    name: 'PRODUCT',
    title: '生产管理',
    port: '8037'
  },
  {
    name: 'EHRER',
    title: 'EHR',
    port: '8038'
  },
  {
    name: 'CLM',
    title: '合同管理',
    port: '8039'
  },
  {
    name: 'LEASE',
    title: '租赁管理',
    port: '8040'
  }
]

const apps = []
appList.forEach((item) => {
  const { name, title, port } = item
  const path = `/${name}/`
  let host = `${window.location.origin}${path}`
  if (process.env.NODE_ENV === 'development') {
    host = `${window.location.protocol}//${window.location.hostname}:${port}`
  }
  if (process.env.VUE_APP_DEVELOPMENT_QIANKUN_HOST) {
    host = `${process.env.VUE_APP_DEVELOPMENT_QIANKUN_HOST}${path}`
  }
  apps.push({
    name,
    title,
    host
  })
})

export {
  appList
}

export function appConfigs () {
  const token = sessionStorage.getItem('SESSION') || localStorage.getItem('SESSION')
  const userCode = sessionStorage.getItem('userCode')
  const userId = sessionStorage.getItem('userId')
  const container = '#app-container'
  const activeRuleRouter = process.env.VUE_APP_NAME + '/app-center/main/'
  const map = {}
  apps.forEach((app) => {
    map[app.name] = {
      name: app.name,
      entry: app.host,
      container: container + '-' + app.name,
      activeRule: '/pba/app-center/main/' + app.name + '/',
      props: {
        userCode: userCode,
        userId: userId,
        token: token,
        routerBase: activeRuleRouter + app.name + '/'
      }
    }
  })
  return map
}

export function getApp (name) {
  apps.forEach((app) => {
    if (app.name === name) {
      return app
    }
  })
}

export function getAppPath (systemCode, resourcePath) {
  return '/app-center/main/' + systemCode + resourcePath
}
