/*
 * @Author: your name
 * @Date: 2020-10-13 12:00:15
 * @LastEditTime: 2020-10-22 16:01:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ant-design-pro-vue\src\utils\request.js
 */
import axios from 'axios'
// import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { types } from '@twit/utils'
// import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 30000, // 请求超时时间
  withCredentials: true
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    const statusCode = error.response.status
    switch (statusCode) {
      case 996:
        document.cookie = 'SESSION=;path=/'
        localStorage.removeItem('SESSION')
        localStorage.removeItem('Access-Token')
        window.open('/idaas/login', '_self')
        // const t = `${process.env.VUE_APP_CAS}?service=${utils.casConfig.service}?redirect=${utils.casConfig.redirect}`
        // window.open(t, '_self')
        // window.open('IDAAS_AUTH_SERVER_URL', '_self')
        // window.open(process.env.VUE_APP_IDAAS, '_self')
        break
      case 401:
        localStorage.removeItem('TOKEN_KEY')
        window.open('/idaas/login', '_self')
        // window.location.href = 'IDAAS_AUTH_SERVER_URL'
        // window.location.href = process.env.VUE_APP_IDAAS
        break
      case 403:
        notification.error({
          message: 'Forbidden',
          description: data.message
        })
        break
      default:
        notification.error({
          message: `HTTP状态码${statusCode},服务状态码${data ? data.code : ''}`,
          description: types.isEmpty(data.msg) ? data.message : data.msg
        })
        break
    }

    // if (error.response.status === 403) {
    //   notification.error({
    //     message: 'Forbidden',
    //     description: data.message
    //   })
    // }
    // if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
    //   notification.error({
    //     message: 'Unauthorized',
    //     description: 'Authorization verification failed'
    //   })
    //   if (token) {
    //     store.dispatch('Logout').then(() => {
    //       setTimeout(() => {
    //         window.location.reload()
    //       }, 1500)
    //     })
    //   }
    // }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  if (config.url.includes(process.env.VUE_APP_API_BASE_URL)) {
    config.url = config.url.replace(process.env.VUE_APP_API_BASE_URL, '')
  }
  // const token = storage.get(ACCESS_TOKEN)
  const userId = storage.get('userId')
  const userCode = storage.get('userCode')
  const token = localStorage.getItem('TOKEN_KEY')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (userId && userCode) {
    config.headers['current-user-id'] = userId
    config.headers['current-user-no'] = userCode
  }
  let tenantId = localStorage.getItem('tenantId')
  const reg = /\d+/g
  if (tenantId) {
    const res = tenantId.match(reg)
    if (res && res[0]) {
      tenantId = res[0]
    }
  }
  config.headers['current-tenant-id'] = tenantId
  config.headers['current-user-code'] = localStorage.getItem('userCode')
  config.headers.authorization = token
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  const res = response.data
  const config = response.config || {}
  // 流程接口返回的字段中不包含"code"字段，所以这里进行兼容处理
  if (res.hasOwnProperty('code') && String(res.code) !== '0') {
    if (config.noTips) {
      return res
    }
    notification.error({
      message: `状态码: ${res.code}`,
      description: types.isEmpty(res.msg) ? res.message : res.msg
    })
  }
  if (!res.hasOwnProperty('code') && res.result && String(res.result) !== '200') {
    notification.error({
      message: `状态码: ${res.result}`,
      description: !types.isEmpty(res.message) ? res.message : res.msg
    })
  }
  return res || true
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
