import bootstrap from './core/bootstrap'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import Vue from 'vue'
import { addGlobalUncaughtErrorHandler } from 'qiankun'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
// import storage from 'store'
// import { APPS } from './config/app.config'

// debugger
// 返回当前 URL 的路径部分
// const token = storage.get(ACCESS_TOKEN)
// const userCode = sessionStorage.getItem('userCode')
// const userId = sessionStorage.getItem('userId')

// 注册子应用
// const container = '#app-container'
// const activeRuleRouter = process.env.VUE_APP_NAME + '/app-center/main/'

// const apps = []
// APPS.forEach(app => {
//   apps.push({
//     name: app.name,
//     entry: app.host,
//     container: container + '-' + app.name,
//     activeRule: '/pba/app-center/main/' + app.name + '/',
//     props: {
//       userCode: userCode,
//       userId: userId,
//       token: token,
//       routerBase: activeRuleRouter + app.name + '/'
//     }
//   })
// })

// registerMicroApps(apps, {
//   beforeLoad: [ app => { } ],
//   beforeMount: [ app => { } ],
//   afterUnmount: [ app => {} ]
// })

// 设置全局未捕获一场处理器
addGlobalUncaughtErrorHandler(event => {
  console.log(event)
})
// 启动微服务
// start({ singular: false })

export default new Vue({
  router,
  store,
  i18n,
  created: bootstrap,
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this // 安装全局事件总线
  }
})
