<template>
  <div>
    <a-layout class="tw-app-center-container">
      <a-layout-sider
        v-model="collapsed"
        :trigger="null"
        collapsible
        class="tw-sider">
        <div
          id="logo"
          class="logo tw-module-title">
          <div>
            <span v-if="!collapsed">应用中心</span>
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              :style="collapsed ? 'margin-right: 15px;': 'margin-right: 0px;'"
              @click="() => (collapsed = !collapsed)" />
          </div>
        </div>
        <a-menu
          ref="menus"
          theme="light"
          mode="inline"
          class="tw-app-menus"
          :style="{ height: menusHeight }"
          v-model="appKeys">
          <a-menu-item
            class="tw-menus-item"
            style="padding: 0 10px"
            v-for="row in apps"
            :key="row.resourceCode"
            @click="() => onAppMenuClick(row)">
            <span class="svg-icon" v-html="getIcon(row)" v-if="!collapsed"></span>
            <span style="font-size: 14px; position: relative; top: 2px;">{{ row.resourceName }}</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-content
          class="tw-content">
          <div class="tw-content-tabs">
            <a-tabs
              v-model="activeKey"
              hide-add
              type="editable-card"
              @edit="onEdit"
              @change="onChange">
              <a-tab-pane
                v-for="pane in panes"
                :key="pane.key"
                :tab="pane.title"
                :closable="pane.closable" />
            </a-tabs>
          </div>
          <keep-alive :max="8">
            <router-view v-if="initLoaded" />
          </keep-alive>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
import { initGlobalState } from 'qiankun'
import utils from '@twit/utils'
import { events as qiankunEvents, pane as qiankunPane } from '@twit/qiankun'
import { listApps, listMenus, listServices } from '@/api/app'
import { MENU_KEYS } from '@/config/router.config'
import { appConfigs } from '@/config/app.config'
import svgIcon from './svg-icon'

let firstAppName = 'PBC'
// 获取PANES标签标题对应的附加参数
const MENUS_QUERY_KEYS = ['key', 'billTypeCode']
// 初始标签列表
const INIT_PANES = [{
  key: 'home',
  title: '首页',
  closable: false,
  path: '/app-center/menus?resourceCode=PBC'
}]

const QIANKUN_STATE = {
  panes: INIT_PANES,
  systemCode: 'PBA'
}

export default {
  name: 'AppLayout',
  data () {
    return {
      svgIcon,
      apps: [],
      appConfigs: appConfigs(),
      appKeys: [],
      collapsed: false,
      actions: undefined,
      startedSystems: [],
      panes: [],
      activeKey: 'home',
      menus: {},
      initLoaded: false,
      menusHeight: 0,
      appCodes: []
    }
  },
  async created () {
    await this.initApps()
    await this.initMenus()
    this.onRouterChange(this.$route)
    this.actions = initGlobalState(QIANKUN_STATE)
    this.actions.onGlobalStateChange((state, prev) => {
      if (!this.startedSystems.includes(state.systemCode)) {
        this.startedSystems.push(state.systemCode)
        this.actions.setGlobalState({ panes: this.panes, systemCode: 'PBA' })
      }
    })
    this.initLoaded = true
    this.clearPanes()
    // 监听qiankunEvents('pane-close')事件,移除对应pane标签
    qiankunEvents.$on('pane-close', (val) => {
      this.remove(val)
    })
    // 监听qiankunEvents('pane-set-title')事件,修改对应pane标题
    qiankunEvents.$on('pane-set-title', (val) => {
      this.panes.forEach(pane => {
        if (pane.key === val.key) {
          pane.title = val.title
        }
      })
      this.actions.setGlobalState({ panes: this.panes })
    })
  },
  mounted () {
    qiankunEvents.$on('modules-flex-view-height-change', () => {
      this.setMenusHeight()
    })
    this.setMenusHeight()
  },
  watch: {
    collapsed: {
      handler () {
        document.getElementById('logo').style.width = this.collapsed ? '80px' : '100%'
      }
    },
    $route: {
      handler (route) {
        this.onRouterChange(route)
        this.$utils.setMenuKey(MENU_KEYS.APP_CENTER)
        this.menusHeight = 'auto'
      }
    },
    menus: {
      handler () {
        this.panes.forEach(item => {
          if (item.key !== 'home') {
            item.title = this.getTitle(item.path)
          }
        })
      }
    }
  },
  methods: {
    getIcon (item) {
      return svgIcon[item.resourceCode]
    },
    setMenusHeight () {
      this.menusHeight = utils.getFlexViewHeight({ ref: this.$refs.menus, offset: 10 }) + 'px'
    },
    clearPanes () {
      const isClearPanes = this.$store.state.isClearPanes
      if (isClearPanes) {
        this.panes = [{
          key: 'home',
          title: '首页',
          closable: false,
          path: '/app-center/menus?resourceCode=' + firstAppName
        }]
        this.$router.push({ path: '/app-center/menus?resourceCode=' + firstAppName })
        this.$utils.setIsClearPanes(false) // 切换租户清空多开后isClearPanes恢复默认值
        // 通知微应用
        this.actions = initGlobalState(QIANKUN_STATE)
        this.actions.onGlobalStateChange((state, prev) => {
          if (!this.startedSystems.includes(state.systemCode)) {
            this.startedSystems.push(state.systemCode)
            this.actions.setGlobalState({ panes: this.panes, systemCode: 'PBA' })
          }
        })
      }
    },
    async initMenus () {
      const { data: data1 } = await listMenus({ appCodes: this.appCodes })
      const array1 = this.$utils.isEmpty(data1) ? [] : data1

      const { data: data2 } = await listServices({ appCodes: this.appCodes })
      const array2 = this.$utils.isEmpty(data2) ? [] : data2

      const menus = {}
      const array = array1.concat(array2)
      array.forEach(item => {
        let resourcePath = ''
        if (item.resourceType === 1) { // 菜单
          resourcePath = item.menuUrl
        } else if (item.resourceType === 2) { // 服务
          resourcePath = item.serviceUrl
        } else if (item.resourceType === 3) { // 前端资源
          resourcePath = item.viewUrl
        }
        const key = '/app-center/main/' + item.frontModuleCode + resourcePath
        menus[key] = item.resourceName
      })

      this.menus = menus
      this.$forceUpdate()
    },
    async initApps () {
      const data = await listApps()
      this.apps = this.$utils.isEmpty(data) ? [] : data
      this.apps.forEach(app => {
        const codes = this.$utils.isEmpty(app.appCodes) ? [] : app.appCodes
        this.appCodes = this.appCodes.concat(codes)
        localStorage.setItem('app-codes-' + app.resourceCode, JSON.stringify(codes))
      })
      localStorage.setItem('all-app-codes', JSON.stringify(this.appCodes))

      firstAppName = this.apps[0].systemCode
      sessionStorage.setItem('firstAppName', firstAppName)
      this.appKeys = [firstAppName]
      this.panes = [{
        key: 'home',
        title: this.apps[0].resourceName || '首页',
        closable: false,
        path: '/app-center/menus?resourceCode=' + firstAppName
      }]
    },
    onAppMenuClick (row) {
      qiankunPane.setTitle('home', row.resourceName)
      if (/^w:/.test(row.resourcePath)) {
        // 跳外链
        const newWindow = window.open(row.resourcePath.split('w:').join(''), '__blank')
        newWindow.opener = null
        return
      }
      this.$router.push('/app-center/menus?resourceCode=' + row.resourceCode).then(() => {
        this.appKeys = [row.resourceCode]
      })
    },
    // 多开
    async onRouterChange (route) {
      if (!this.$utils.isEmpty(route.query) && this.$route.query.resourceCode === 'search') {
        this.appKeys = []
      } else if (this.$route.name === 'app-center-main') {
        const title = this.getTitle(this.$route.path, this.$route.query)
        this.savePane(route.fullPath, title, route.fullPath)
      } else if (this.$route.name === 'app-center-menus') {
        this.appKeys = [route.query.resourceCode]
        this.savePane('home', '首页', route.fullPath)
      }
    },
    getTitle (path, query) {
      let key = path
      const params = []
      MENUS_QUERY_KEYS.forEach(key => {
        if (query[key]) {
          params.push(key + '=' + query[key])
        }
      })
      key += params.length ? '?' + params.join('&') : ''
      let title = this.$route.meta.title
      if (!this.$utils.isEmpty(this.menus[key])) {
        title = this.menus[key]
      }
      if (title === '功能') {
        this.panes.forEach((pane, i) => {
          if (pane.path === this.$route.fullPath) {
            title = pane.title
          }
        })
      }
      return title
    },
    onChange (activeKey) {
      if (!this.$utils.isEmpty(activeKey)) {
        let newPath = ''
        this.panes.forEach(item => {
          if (item.key === activeKey) {
            newPath = item.path
          }
        })
        this.activeKey = activeKey
        const app = this.apps.filter(item => item.resourceCode === this.$route.query.resourceCode)
        if (app.length) {
          this.$nextTick(() => {
            qiankunPane.setTitle('home', app[0].resourceName)
          })
        }
        this.$router.push(newPath)
      }
    },
    onEdit (targetKey, action) {
      this[action](targetKey)
    },
    remove (key) {
      let activeKey = this.activeKey
      let lastIndex
      this.panes.forEach((pane, i) => {
        if (pane.key === key) {
          lastIndex = i - 1
        }
      })
      const panes = this.panes.filter(pane => pane.key !== key)
      if (panes.length && activeKey === key) {
        activeKey = (lastIndex >= 0) ? panes[lastIndex].key : panes[0].key
      }
      this.panes = panes
      qiankunEvents.$emit('set-pane-key', { path: this.$route.fullPath, key: null })
      this.actions.setGlobalState({
        panes: this.panes
      })
      this.onChange(activeKey)
    },
    savePane (key, title, path, active = true, closable = true) {
      if (key === 'home' || key === 'search') {
        key = 'home'
        this.panes[0].path = path
      } else {
        let pane
        this.panes.forEach(item => {
          if (item.key === key) {
            pane = item
          }
        })

        if (this.$utils.isEmpty(pane)) {
          pane = {
            key: key,
            title: title,
            path: path,
            closable: closable
          }
          this.panes.push(pane)
          if (this.actions) {
            qiankunEvents.$emit('set-pane-key', { path: this.$route.fullPath, key: Date.now() })
          }
        } else {
          Object.assign(pane, {
            key: key,
            title: title,
            path: path,
            closable: closable
          })
        }
      }
      this.onChange(active ? key : this.activeKey)
    }
  }
}
</script>

<style lang="less" scoped>
.app-footer {
  text-align: center;
  background-color: #fff;
  margin-top: 10px;
}
.loading-spin {
  display: block;
}
.tw-app-menus {
  overflow-y: auto;
  overflow-x: hidden;
  border: 0;
  background: #f2f3f5;
}
.tw-app-menus {
  &.ant-menu-inline-collapsed {
    .tw-menus-item {
      padding: 0 10px !important;
      span {
        width: 100% !important;
        display: block !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.tw-menus-item {
  border-radius: 4px;
  margin: 10px;
  width: calc(100% - 20px) !important;
  &.ant-menu-item-selected {
    background: #d9e7f6 !important;
    &::after {
      display: none;
    }
  }
  &:hover {
    background: #d9e7f6;
    ::v-deep {
      .svg-icon {
        path {
          fill: rgba(0, 128, 255, 0.9);
        }
      }
    }
  }
  .icon {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
}
.line {
  border-left: 2px solid #f0f2f5;
  text-align: center;
}
.ant-layout-header {
  height: 60px;
  padding: 0 80px;
  line-height: 60px;
  background: rgb(42, 63, 152);
}
.tw-app-center-container .trigger {
  font-size: 16px;
  line-height: 50px;
  padding: 0 0px;
  cursor: pointer;
  transition: color 0.3s;
  float: right;
}
.tw-app-center-container .logo {
  position: relative;
  height: 50px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 50px;
  margin: 0;
  text-align: left;
  font-size: 16px;
}

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #acbcd5;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 3px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 2px solid #1890ff;
}

.ant-layout-sider {
  background: #fff;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
  width: 200px;
}
.tw-header {
  background: #fff;
  padding: 0;
  height: 50px;
  padding-left: 12px;
  line-height: 50px;
  font-size: 16px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
}

.tw-content {
  // margin-left: 10px;
  min-height: 500px;
  background-color: #fff;
  .tw-content-tabs {
    ::v-deep {
      .ant-tabs-tab {
        border: 0 !important;
        padding: 0 35px !important;
      }
      .ant-tabs-tab {
        background: #f2f3f5 !important;
        border-radius: 10px 10px 0 0 !important;
      }
      .ant-tabs-tab-active {
        background: #fff !important;
        border-radius: 10px 10px 0 0 !important;
      }
      .ant-tabs-nav-container {
        background: #f2f3f5;
      }
    }
  }
}

.tw-sider {
  min-height: calc(100vh - 110px);
  background: #f2f3f5;
  // box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.15)
}
.ant-card-bordered {
  // border-radius: 10px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
}
.box {
  text-align: center;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
}
.box-div {
  padding-top: 10px;
}
.box-div-title {
  padding-bottom: 10px;
}
.box-div-delete {
  position: absolute;
  right: 5px;
  top: 5px;
}
.box:hover {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.15);
}
.box:hover .box-div-delete {
  display: block;
}

::v-deep {
  .svg-icon {
    svg {
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}
.ant-menu-item-selected {
  ::v-deep {
    .svg-icon {
      path {
        fill: rgba(0, 128, 255, 0.9);;
      }
    }
  }
}
</style>
