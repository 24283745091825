const friendlyLinks = [
  { url: 'https://www.talkweb.com.cn/', name: '拓维官网' },
  { url: 'https://www.hrgame.com.cn/', name: '火溶信息' },
  { url: 'https://www.seaskylight.com/', name: '海云天科技' },
  { url: 'https://lhxy.yunxuetang.cn/login.html', name: '蓝海学院' },
  { url: 'https://paas.talkweb.com.cn/', name: '梧桐云平台' },
  { url: 'https://git.talkweb.com.cn/', name: '拓维Gitlab' },
  { url: 'https://yunchuang.talkweb.com.cn/', name: '云创信息化' },
  { url: 'http://vbmp.talkedu.cn', name: '教育可视化业务' }
]

const defaultTodoTabs = [
  { title: '我的待办', key: 1, count: 0, iconType: 'todo' },
  { title: '我的申请', key: 2, count: 0, iconType: 'apply' },
  { title: '我的已办', key: 3, count: 0, iconType: 'done' }
]

const newsTabs = [
  { title: '公司新闻', key: 1 },
  { title: '集团公告', key: 2 },
  { title: '业务动态', key: 3 },
  { title: '体系动态', key: 4 }
]

export {
  friendlyLinks,
  defaultTodoTabs,
  newsTabs
}
