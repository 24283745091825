// eslint-disable-next-line
import { BlankLayout } from '@/layouts'
import AppLayout from '@/views/AppCenter/index.vue'
import HomeLayout from '@/views/home/index.vue'

export const MENU_KEYS = {
  HOME: 'home',
  APP_CENTER: 'app-center',
  ADDRESS_LIST: 'address-list',
  USER_CENTER: 'user-center'
}

export const menusRouters = [
  {
    path: '/' + MENU_KEYS.HOME,
    name: MENU_KEYS.HOME,
    redirect: '/home/index',
    meta: { title: '首页', iconType: 'home' },
    component: HomeLayout,
    children: [
      {
        path: '/home/index',
        name: 'home-index',
        meta: { title: '首页' },
        component: () => import('@/views/home/Home')
      },
      // 指南
      {
        path: 'adminguide',
        name: 'adminguide',
        meta: { title: '行政办事指南' },
        component: () => import('@/views/allGuides/AdminGuide')
      },
      {
        path: 'hrguide',
        name: 'hrguide',
        meta: { title: '人资办事指南' },
        component: () => import('@/views/allGuides/HrGuide')
      },
      {
        path: 'financialguide',
        name: 'financialguide',
        meta: { title: '财务办事指南' },
        component: () => import('@/views/allGuides/FinancialGuide')
      },
      {
        path: 'itguide',
        name: 'itguide',
        meta: { title: 'IT办事指南' },
        component: () => import('@/views/allGuides/ItGuide')
      },
      {
        path: 'brandguide',
        name: 'brandguide',
        meta: { title: '品牌营销部办事指南' },
        component: () => import('@/views/allGuides/BrandGuide')
      },
      // 制度，动态，公告
      {
        path: 'list',
        name: 'list',
        meta: { title: '列表' },
        component: () => import('@/views/details/List')
      },
      {
        path: 'detail',
        name: 'detail',
        target: '_blank',
        meta: { title: '详情' },
        component: () => import('@/views/details/Detail')
      },
      // 菜单
      {
        path: 'food-menu',
        name: 'food-menu',
        meta: { title: '行政办事指南' },
        component: () => import('@/views/food-menu/index')
      }
    ]
  },
  // {
  //   path: '/',
  //   title: '推荐应用',
  //   name: 'recommend_app',
  //   meta: { title: '推荐应用', isItemList: true, type: 'recommend-app', iconType: 'tuijian' }
  // },
  {
    path: '/' + MENU_KEYS.APP_CENTER,
    name: MENU_KEYS.APP_CENTER,
    redirect: {
      path: '/app-center/menus'
    },
    meta: { title: '应用中心', iconType: 'app-center' },
    component: AppLayout,
    children: [
      {
        path: '/app-center/menus',
        name: 'app-center-menus',
        meta: { title: '应用中心-菜单' },
        component: () => import('@/views/AppCenter/Menus.vue'),
        children: []
      },
      {
        path: '/app-center/main/*',
        name: 'app-center-main',
        meta: { title: '功能' },
        component: () => import('@/views/AppCenter/Main.vue'),
        children: []
      }
    ]
  },
  {
    path: '/address-list',
    name: MENU_KEYS.ADDRESS_LIST,
    meta: { title: '通讯录', iconType: 'tongxunlu', hidden: true },
    component: () => import('@/views/address-list/index.vue'),
    children: []
  },
  {
    path: '',
    name: 'tw-community',
    meta: { title: '拓维圈', iconType: 'twq' },
    url: '/wiki-index',
    children: []
  },
  {
    path: '/tw-help',
    name: 'tw-help',
    meta: { title: '企业WiKi', iconType: 'bszn' },
    // component: () => import('@/views/TWHelp/index.vue'),
    url: `/wiki-index/wiki`,
    children: []
  },
  {
    path: '/address-list',
    name: 'address-list12',
    meta: { title: '企业邮箱', iconType: 'email' },
    url: 'https://exmail.qq.com/cgi-bin/loginpage'
  },
  {
    path: '/list',
    name: 'list',
    meta: { hidden: true },
    component: () => import('@/views/details/List')
  },
  {
    path: '/list?title=公司新闻&type=news',
    name: 'list',
    meta: { title: '资讯盒子', iconType: 'container' }
  },
  {
    path: '/' + MENU_KEYS.USER_CENTER,
    name: MENU_KEYS.USER_CENTER,
    redirect: '/user-center/index',
    meta: { title: '个人中心', hidden: true },
    component: () => import('@/views/UserCenter'),
    children: [
      // 个人办公
      {
        path: 'index',
        name: 'index',
        meta: { pTitle: '个人中心', title: '首页' },
        component: () => import('@/views/users/index.vue')
      },
      // 个人办公
      {
        path: 'todouser',
        name: 'todouser',
        meta: { pTitle: '个人办公', title: '我的待办' },
        component: () => import('@/views/users/work/ToDoUser')
      },
      {
        path: 'todoread',
        name: 'todoread',
        meta: { pTitle: '个人办公', title: '我的待阅' },
        component: () => import('@/views/users/work/ToDoRead')
      },
      {
        path: 'meeting',
        name: 'meeting',
        meta: { pTitle: '个人办公', title: '我的会议' },
        component: () => import('@/views/users/work/Meeting')
      },
      {
        path: 'mail',
        name: 'mail',
        meta: { pTitle: '个人办公', title: '我的邮件' },
        component: () => import('@/views/users/work/Mail')
      },
      {
        path: 'apply',
        name: 'apply',
        meta: { pTitle: '个人办公', title: '我的申请' },
        component: () => import('@/views/users/work/Apply')
      },
      {
        path: 'done',
        name: 'done',
        meta: { pTitle: '个人办公', title: '我的已办' },
        component: () => import('@/views/users/work/Done')
      },
      {
        path: 'draft',
        name: 'draft',
        meta: { pTitle: '个人办公', title: '我的草稿' },
        component: () => import('@/views/users/work/Draft')
      },
      {
        path: 'submitted/my-work',
        name: 'submitted/my-work',
        meta: { pTitle: '个人办公', title: '我的报工', path: '/user-center/submitted/my-work' },
        component: () => import('@/views/users/work/work-submitted')
      },
      {
        path: 'submitted/:type',
        name: 'submitted',
        meta: { pTitle: '个人办公', title: '我的报工', path: '/user-center/submitted/my-work' },
        component: () => import('@/views/users/work/work-submitted')
      },
      {
        path: 'work-report-approval',
        name: 'work-report-approval',
        meta: { pTitle: '个人办公', title: '报工审核', path: '/user-center/work-report-approval' },
        component: () => import('@/views/users/sub-page/work-report-approval.vue')
      },
      {
        path: 'my-device',
        name: 'my-device',
        meta: { pTitle: '个人办公', title: '我的设备', path: '/user-center/my-device' },
        component: () => import('@/views/users/sub-page/my-device.vue')
      },
      {
        path: 'my-account',
        name: 'my-account',
        meta: { pTitle: '个人办公', title: '我的账户', path: '/user-center/my-account' },
        component: () => import('@/views/users/sub-page/my-account/my-account.vue')
      },
      {
        path: 'message',
        name: 'message',
        meta: { pTitle: '个人办公', title: '我的消息' },
        component: () => import('@/views/users/work/Message')
      },
      // 个人设置
      {
        path: 'basicsettings',
        name: 'basicsettings',
        meta: { pTitle: '个人设置', title: '基本设置' },
        component: () => import('@/views/users/set/BasicSettings')
      },
      {
        path: 'securitysettings',
        name: 'securitysettings',
        meta: { pTitle: '个人设置', title: '安全设置' },
        component: () => import('@/views/users/set/SecuritySettings')
      },
      {
        path: 'messagesettings',
        name: 'messagesettings',
        meta: { pTitle: '个人设置', title: '消息设置' },
        component: () => import('@/views/users/set/MessageSettings')
      },
      {
        path: 'custom',
        name: 'custom',
        meta: { pTitle: '个人设置', title: '自定义工作台' },
        component: () => import('@/views/users/set/Custom')
      }
    ]
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    name: 'index',
    redirect: '/' + MENU_KEYS.HOME,
    component: BlankLayout,
    meta: { title: '' },
    children: []
  }
].concat(menusRouters)

export const showMenu = () => {
  return menusRouters.filter((item) => {
    return !item.meta.hidden
  })
}

export const rootRouter = {}
export const routerMap = {}
