import request from '@/utils/request'
import storage from 'store'
// const HAECHI = process.env.VUE_APP_API_HAECHI
import qs from 'qs'

const tenantId = storage.get('tenantId')
const userCode = storage.get('userCode')
const Api = {
  Logout: '/logout/cas',
  regionSelect: '/business-center/v1/region/select', // 查询地区
  bankcardSubbranch: '/business-center-config-web/v1/user/bank/card/subbranch/page', // 查询银行编号
  bankcard: '/business-center-config-web/v1/user/bank/card', // 查询银行信息
  bankcardCreate: '/business-center-config-web/v1/user/bank/card/create', // 新增银行信息
  bankcardDelete: '/business-center-config-web/v1/user/bank/card/delete', // 删除银行信息
  bankcardUpdate: '/business-center-config-web/v1/user/bank/card/update', // 修改银行信息
  // getLoginUser: '/asset/v1/login/user',
  getLoginUser: '/wutong-idaas-account/current',
  getTenant: '/business-center/v1/login/', // 获取租户/ /business-center/v1/login/{userCode}/tenant
  setTenantId: '/asset/v1/login/setTenantId', // /asset/v1/login/setTenantId// 切换租户
  getDict: '/business-center/dict/getDictSelect', // 字典
  getMailUrl: '/business-center/v1/email/mail/usersignurlbycode', // 获取跳转邮箱
  setUserQueryHistory: '/user-end/v1/userQueryHistory/saveQueryRecord', // 设置用户查询关键字
  getUserQueryHistory: '/user-end/v1/userQueryHistory/queryHistoryRecords', // 获取用户查询关键字
  checkUserProgramInfo: '/user-end/v1/featurePermission/checkUserProgramInfo', // 校验权限
  adduserrecentvisitedres: '/user-end/v1/userprefer/adduserrecentvisitedres', // 添加用户操作应用
  getuserrecentvisitedres: '/user-end/v1/userprefer/getuserrecentvisitedres', // 查询历史常应用
  updatePassWord: '/wutong-idaas-account/user/update-password', // 修改密码
  getUserByUserCode: '/talkweb-user-center/user/inner/get-user-by-code' // 查询用户信息
}

export function getUserByUserCode (params) {
  return request({
    url: Api.getUserByUserCode,
    method: 'get',
    params: params
  })
}

export function getUserCode () {
  return storage.get('userCode') === undefined ? '00000000' : storage.get('userCode')
}

export function setTenantId (data) {
  return request({
    url: Api.setTenantId,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify(data)
  })
}
export function getMailUrl (params) {
  const Myparams = { userCode }
  return request({
    url: Api.getMailUrl,
    method: 'get',
    params: Myparams
  })
}
// 字典
export function getDict (params) {
  const Myparams = { ...params, tenantId }
  return request({
    url: Api.getDict,
    method: 'get',
    params: Myparams
  })
}
export function regionSelect (params) {
  return request({
    url: Api.regionSelect,
    method: 'post',
    data: params
  })
}
export function bankcardSubbranch (params) {
  return request({
    url: Api.bankcardSubbranch,
    method: 'post',
    data: params
  })
}
export function bankcard (userCode) {
  return request({
    url: Api.bankcard + `/${userCode}/info`,
    method: 'get'
    //   data: params
  })
}
export function bankcardCreate (params) {
  return request({
    url: Api.bankcardCreate,
    method: 'post',
    data: params
  })
}
export function bankcardUpdate (params) {
  return request({
    url: Api.bankcardUpdate,
    method: 'put',
    data: params
  })
}
export function bankcardDelete (id) {
  return request({
    url: Api.bankcardDelete + `/${id}`,
    method: 'DELETE'
    // data: params
  })
}
export function getTenant (userCode) {
  return request({
    url: Api.getTenant + userCode + '/tenant',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function getLoginUser () {
  return request({
    url: Api.getLoginUser,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout () {
  return request({
    url: Api.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * @description 查询用户
 * @param keyword 名字/简拼/手机号
 * @return {*}
 */
export async function queryUser (keyword) {
  const res = await request({
    url: '/kirin-hr-biz/user/getUserProgramInfo',
    method: 'get',
    params: {
      keyword
    }
  })
  if (res.code === 0) {
    return res.data
  }
  console.error('Error reported by the server')
  return []
}

/**
 * @param sign 名字/简拼/手机号
 * @return {*}
 */
export async function setUserQueryHistory (sign, userCode) {
  const res = await request({
    url: Api.setUserQueryHistory,
    method: 'post',
    data: {
      userCode,
      queryKey: sign
    }
  })
  if (res.code === 0) {
    return res.data || []
  }
  console.error('Error reported by the server')
  return []
}

export async function getUserQueryHistory (code) {
  const res = await request({
    url: Api.getUserQueryHistory,
    method: 'get',
    params: {
      userCode: code
    }
  })
  if (res.code === 0) {
    return res.data || []
  }
  console.error('Error reported by the server')
  return []
}

export async function checkUserProgramInfo (uid) {
  const res = await request({
    url: Api.checkUserProgramInfo,
    method: 'get',
    params: {
      userCode: uid
    }
  })
  if (res.code === 0) {
    return res.data || []
  }
  console.error('Error reported by the server')
  return []
}

/**
 * @description
 * data {
 *   "iconSrc": "string",
 *   "moduleCode": "string",
 *   "resourceCode": "string",
 *   "resourceName": "string",
 *   "src": 应用来源
 *   "resourcePath": "string",
 *   "systemCode": "string"
 * }
 * @return {Promise<any|*[]>}
 */
export async function adduserrecentvisitedres (data) {
  const res = await request({
    url: Api.adduserrecentvisitedres,
    method: 'post',
    data
  })
  if (res.code === 0) {
    return res.data || []
  }
  console.error('Error reported by the server')
  return []
}

export async function getuserrecentvisitedres (src) {
  const res = await request({
    url: Api.getuserrecentvisitedres,
    method: 'get',
    params: {
      src
    }
  })
  if (res.code === 0) {
    return res.data || []
  }
  console.error('Error reported by the server')
  return []
}

export async function updatePassWord (data) {
  const res = await request({
    url: Api.updatePassWord,
    method: 'post',
    data
  })
  return res
}

export async function getUserCenterUserInfo (userId = '') {
  const res = await request({
    url: '/talkweb-user-center/user/inner/get-user-by-id',
    params: {
      'uId': userId || localStorage.getItem('uniqueNo')
    }
  })
  if (res.code === 0) {
    const data = res.data || {}
    const extra = data.extra || []
    const extraObj = {}
    extra.forEach((item) => {
      extraObj[item.filedName] = item.filedValue
    })
    data.extraObj = extraObj
    return data
  }
  return res
}

export function updateUserCenterUserInfo (params) {
  return request.post('/talkweb-user-center/user/update', params)
}
