<template>
  <div class="box" :class="{ 'work-center': itemType === 'my-app' }" style="height: 100%">
    <div class="flex-center" style="justify-content: start;height: 100%">
      <div class="menu-hover-box flex-center">
        <tw-icons style="margin-right: 6px" icon-type="tuijian"/>{{ title }}&nbsp;&nbsp;
        <svg xmlns="http://www.w3.org/2000/svg" class="styles__StyledSVGIconPathComponent-sc-1fza4jv-0 fDoNTf svg-icon-path-icon fill" viewBox="0 0 1024 1024" width="10" height="10"><defs data-reactroot=""></defs><g><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></g></svg>
      </div>
    </div>
    <div class="menu-list-box" type="primary" @click="toggleCollapsed">
      <div class="menu-content">
        <div v-show="row.appList && row.appList.length" :key="i" v-for="(row, i) of getData">
          <ul>
            <li :key="index" @click="toAppCenter(item)" v-for="(item, index) of row.appList">
              <a-tooltip
                placement="bottomLeft"
                class="flex-center"
                style="flex-direction: column"
                :overlayStyle="{ fontSize: '12px' }">
                <div class="box-div">
                  <a-avatar
                    shape="square"
                    :size="35"
                    :src="getSrc(item)"
                  />
                </div>
                <div v-if="item.resourceName" :title="item.resourceName" class="box-div box-div-title">{{ item.resourceName }}</div>
              </a-tooltip>
            </li>
          </ul>
        </div>
        <div
          class="cp"
          style="height: 40px;line-height:40px;font-size: 14px;color: rgba(102, 102, 102, 100);border-top: 1px solid #e8e8e8;text-align: center"
          @click="goAppCenter"
          v-show="itemType === 'recommend-app'"
        >
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { listAllResource, listApps } from '@/api/app'
import { setRecentAppCache } from '@/utils/recent-app-service'
import TwIcons from '@/components/tw-icons'
const submitWork = 'SUBMIT_WORK'
export default {
  name: 'MenuItemList',
  components: { TwIcons },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    itemType: {
      type: String,
      default: () => 'my-app' // recommend-app
    }
  },
  data () {
    return {
      images: {
        HR: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230427/084042/renliziyuan.png',
        ADMINS: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230427/064686/hangzhengzonghe.png',
        FOL: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230427/018895/caiwuguanli.png',
        BMS: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230515/030552/%E8%BF%90%E8%90%A5%E7%AE%A1%E7%90%86.png',
        PMS: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230515/068340/xiangmuguanli.png',
        BRAND: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230427/097685/pinpaiyingxiao.png',
        SRM: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20240205/074048/%E9%A4%90%E5%88%B8%E9%A2%86%E5%8F%96%E8%AE%B0%E5%BD%95.png',
        REPORTS: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230515/038116/%E6%8A%A5%E8%A1%A8%E7%AE%A1%E7%90%86.png',
        PBC: 'https://it.talkweb.com.cn/api/business-center/modulefiles/defaultdirserver/download/20230427/006150/a-kongzhizhongxin1.png'
      },
      collapsed: false,
      workCenter: [{
        title: '工作中心',
        appList: []
      }],
      recommendApps: [{
        title: '员工自助',
        resourceCodeList: [
          submitWork,
          'MENU-1635474353686-gzsq8650', // 盖章申请
          'MENU-1635154856511-cqsq6224', // 餐券申请
          'MENU-1635234984893-ycsq3609' // 用车申请
        ],
        appList: []
      }, {
        title: '财务报销',
        resourceCodeList: [
          '1000008-YHFW-000004', // 差旅报销
          '1000008-YHFW-000005', // 招待报销
          '1000008-YHFW-000006', // 定额报销
          '1000008-YHFW-000008' // 付款通知单
        ],
        appList: []
      }, {
        title: '考勤打卡',
        resourceCodeList: [
          'MENU-1637807732980-ydbdsq2772', // 异动补单
          'MENU-1637807732980-qjsq0732', // 请假申请
          'MENU-1637807732980-jbsq4163', // 加班申请
          'MENU-1637807732980-gcsq4892' // 出差申请
        ],
        appList: []
      }],
      defaultFunctionImg: require('@/assets/function_default.png'),
      submitWorkIcon: require('@/assets/submit-work.png')
    }
  },
  mounted () {
    if (!this.$utils.isEmptyObject(this.$store.getters.userInfo)) {
      this.init()
    } else {
      this.$bus.$on('userInfoChange', () => {
        this.init()
      })
    }
  },
  computed: {
    getData () {
      const itemType = this.itemType
      return itemType === 'my-app' ? this.workCenter : this.recommendApps
    }
  },
  methods: {
    init () {
      if (this.itemType === 'my-app') {
        listApps().then((res) => {
          this.workCenter[0].appList = this.$utils.isEmpty(res) ? [] : res
        })
      } else if (this.itemType === 'recommend-app') {
        this.recommendApps.forEach((vo) => {
          if (vo.resourceCodeList.includes(submitWork)) {
            vo.appList.push({
              remark: '我的报工',
              icon: this.submitWorkIcon,
              isUserCenter: true,
              moduleCode: 'GERENBANGONG',
              resourceCode: 'wo-de-bao-gong',
              resourceName: '我的报工',
              resourcePath: '/submitted/my-work',
              systemCode: 'USERCENTER'
            })
          }
        })
        listAllResource([]).then((res) => {
          if (res.code === 0) {
            this.userAllAppList(res.data)
          }
        })
      }
    },
    toggleCollapsed () {
      this.collapsed = !this.collapsed
    },
    goAppCenter () {
      this.$router.push('/app-center/menus')
    },
    userAllAppList (appList) {
      if (!appList && !appList.length) {
        return
      }
      appList.forEach((item) => {
        this.recommendApps.forEach((vo) => {
          if (vo.resourceCodeList.includes(item.resourceCode)) {
            vo.appList.push(item)
          }
        })
      })
    },
    getSrc (item) {
      return this.images[item.moduleCode] ? this.images[item.moduleCode] : this.defaultFunctionImg
    },
    toAppCenter (item) {
      setTimeout(() => {
        let url = ''
        if (this.itemType === 'my-app') {
          if (/^w:/.test(item.resourcePath)) {
            // 跳外链
            url = item.resourcePath.split('w:').join('')
            const newWindow = window.open(url, '__blank')
            newWindow.opener = null
            return
          }
          url = `/app-center/menus?resourceCode=${item.resourceCode || item.moduleCode}`
        } else if (this.itemType === 'recommend-app') {
          item.iconSrc = this.getSrc(item)
          item.src = 'recommend'
          setRecentAppCache(item)
          if (item.isUserCenter) { // 用户中心
            url = `/user-center${item.resourcePath}`
          } else {
            url = `/app-center/main/${item.moduleCode }${item.resourcePath}`
          }
        }
        this.$router.push(url)
      }, 30)
    }
  }
}
</script>

<style scoped lang="less">
.box {
  min-width: 50px;
  margin-left: 15px;
  .menu-hover-box {
    padding: 0 8px;
    height: 36px;
    border-radius: 5px;
  }
  .menu-list-box {
    .menu-content {
      width: 0;
      padding-top: 5px;
      display: none;
      position: absolute;
      top: 56px;
      left: -200px;
      padding-left: 10px;
      z-index: 998;
      transition-property: width;
      transition-duration: 2s;
      color: black;
      .title {
        font-size: 13px;
        font-weight: 600;
        margin: 1%;
      }
      ul {
        background-color: rgba(255, 255, 255, 100);
        box-shadow: 0 1px 6px 0 rgba(216, 227, 253, 100);
        border-radius: 8px;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        margin: 0 -5px;
        padding: 10px 0;
        li {
          width: 128px;
          margin: 0 10px 10px;
          height: 88px;
          border-radius: 4px;
          .box-div {
            height: 50px;
          }
          .box-div-title {
            height: 33px;
            line-height: 33px;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            width: calc(100% - 50px);
          }
          >span {
            border-radius: 8px;
          }
          >span:hover {
            cursor: pointer;
            background: #EDEEEE;
          }
        }
      }
    }
  }
}

.box:hover .menu-content {
  display: block!important;
  width:595px;
}

.box:hover .menu-hover-box {
  background-color: #E5F2FF;
}

//.work-center:hover {
//  background-color: rgba(27,45,98,1);
//  .svg-icon-path-icon {
//    fill: white;
//  }
//}

.work-center {
  .menu-content {
    //width: 430px!important;
  }
}

.svg-icon-path-icon {
  fill: currentColor;
}
</style>
