<template>
  <a-config-provider :locale="locale">
    <div id="app-main" class="global-min-width">
      <a-layout class="app-main layout">
        <a-affix style="height: 60px;">
          <a-modal width="300px" v-model="tanantVisible" title="切换租户" @ok="handleTanantOk">
            <template slot="footer">
              <div></div>
            </template>
            <a-button
              block
              v-for="item in tanantArr"
              :key="item.id"
              size="large"
              type="dashed"
              class="main-button"
              :disabled="item.id === tanantArr[0].id"
              @click="changeTanant(item)"
            >{{ `${item.id === tanantArr[0].id ? '(当前)' : ''} ${item.name} | ${item.description}` }}</a-button>
          </a-modal>

          <a-modal width="600px" v-model="passWordVisible" title="修改密码" @cancel="cancelPassWord" @ok="handlePassWord">
            <a-form-model
              ref="ruleForm"
              :model="formData"
              :rules="rules"
              v-twit-formfit
            >
              <a-form-model-item v-twit-formfit label="旧密码" prop="oldPassWord">
                <a-input v-model="formData.oldPassWord" type="password" placeholder="请输入旧密码" />
              </a-form-model-item>

              <a-form-model-item v-twit-formfit label="新密码" prop="newPassWord">
                <a-input v-model="formData.newPassWord" type="password" placeholder="请输入新密码（至少6位，包含大小写和特殊字符）" />
              </a-form-model-item>

              <a-form-model-item v-twit-formfit label="确认密码" prop="againPassWord">
                <a-input v-model="formData.againPassWord" type="password" placeholder="请确认新密码" />
              </a-form-model-item>
              <span style="color: #999; margin-top: 5px; display: inline-block;">注：此修改会同步修改公司所有平台密码（svn、git、上网认证等）</span>
            </a-form-model>

          </a-modal>

          <a-layout-header style="box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);">
            <div style="height: 100%;">
              <div style="height: 100%; float: left;" class="logo">
                <div class="logo-img" @click="goHome"></div>
                <div style="height: 100%" class="menus">
                  <MenuItemList item-type="my-app" title="工作中心" />
                </div>
              </div>
              <div style="display: inline-block; margin-left: 14%; float: left;">
                <a-input
                  id="search"
                  class="search"
                  @pressEnter="onSearch"
                  v-model="queryFuzzy"
                  placeholder="请输入功能名称"
                >
                  <template #prefix>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="64 64 896 896" width="15" height="15">
                      <defs data-reactroot />
                      <g>
                        <path
                          d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"
                        />
                      </g>
                    </svg>
                  </template>
                </a-input>
              </div>
              <div class="right-wrap">
                <a-tooltip @click="goMsgCenter" placement="bottom" title="消息中心">
                  <a-badge :dot="msgTotal > 0" class="badge">
                    <!--<tw-icons class="cp" style="font-size: 16px;" icon-type="message" />-->
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#656A72" viewBox="0 0 24 24" width="24" height="24"><defs data-reactroot=""></defs><g><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"></path></g></svg>
                  </a-badge>
                </a-tooltip>
                <a-tooltip class="max-icon" placement="bottom" title="最大化">
                  <svg
                    @click="toFullScreen"
                    style="height: 30px; width: 30px"
                    xmlns="http://www.w3.org/2000/svg"
                    class="wos"
                    viewBox="64 64 896 896"
                  >
                    <defs data-reactroot />
                    <g>
                      <path
                        d="M290 236.4l43.9-43.9a8.01 8.01 0 0 0-4.7-13.6L169 160c-5.1-.6-9.5 3.7-8.9 8.9L179 329.1c.8 6.6 8.9 9.4 13.6 4.7l43.7-43.7L370 423.7c3.1 3.1 8.2 3.1 11.3 0l42.4-42.3c3.1-3.1 3.1-8.2 0-11.3L290 236.4zm352.7 187.3c3.1 3.1 8.2 3.1 11.3 0l133.7-133.6 43.7 43.7a8.01 8.01 0 0 0 13.6-4.7L863.9 169c.6-5.1-3.7-9.5-8.9-8.9L694.8 179c-6.6.8-9.4 8.9-4.7 13.6l43.9 43.9L600.3 370a8.03 8.03 0 0 0 0 11.3l42.4 42.4zM845 694.9c-.8-6.6-8.9-9.4-13.6-4.7l-43.7 43.7L654 600.3a8.03 8.03 0 0 0-11.3 0l-42.4 42.3a8.03 8.03 0 0 0 0 11.3L734 787.6l-43.9 43.9a8.01 8.01 0 0 0 4.7 13.6L855 864c5.1.6 9.5-3.7 8.9-8.9L845 694.9zm-463.7-94.6a8.03 8.03 0 0 0-11.3 0L236.3 733.9l-43.7-43.7a8.01 8.01 0 0 0-13.6 4.7L160.1 855c-.6 5.1 3.7 9.5 8.9 8.9L329.2 845c6.6-.8 9.4-8.9 4.7-13.6L290 787.6 423.7 654c3.1-3.1 3.1-8.2 0-11.3l-42.4-42.4z"
                      />
                    </g>
                  </svg>
                </a-tooltip>
                <!--                <a-tooltip placement="bottom" title="问题反馈">-->
                <!--                  <a-icon type="question-circle" class="wos" @click="openQuestion" />-->
                <!--                </a-tooltip>-->

                <div
                  :style="{ top: helpBtnTop }"
                  style="user-select: none; color: #656A72;position: fixed;right: 6px; z-index: 99999;background: white"
                  id="page-action-btn-slider"
                  v-drag
                  v-if="isHomePage"
                  class="page-action-btn b-r-8"
                >
                  <div @click.stop="() => {}" v-show="userInputSearch" class="flex-center tong-xun-lu-input">
                    <a-input-search
                      class="b-r-8"
                      :max-length="30"
                      @search="toSearchUser"
                      placeholder="找同事"
                      style="background-color: #f8fafc; border: none;"
                    />
                  </div>
                  <a-tooltip class="max-icon" placement="left" title="找同事">
                    <div @click.stop="triggerUserInputSearch" v-show="!disabledUserSearch" :class="{'active': userInputSearch}" class="action-item b-r-8 flex-center">
                      <tw-icons icon-type="tongxunlu" />
                      <div class="action-item-tool-vo-title">找同事</div>
                    </div>
                  </a-tooltip>
                  <a-divider v-show="!disabledUserSearch" style="margin: 0" />
                  <a-tooltip class="max-icon" placement="left" title="提工单">
                    <div @click="openQuestion" class="action-item b-r-8 flex-center">
                      <tw-icons icon-type="problem" />
                      <div class="action-item-tool-vo-title">提工单</div>
                    </div>
                  </a-tooltip>
                  <a-divider style="margin: 0" />
                  <a-tooltip class="max-icon" placement="left" title="人事咨询">
                    <div @click="openHrSession" class="action-item b-r-8 flex-center">
                      <tw-icons icon-type="hr-session" />
                      <div class="action-item-tool-vo-title">人事咨询</div>
                    </div>
                  </a-tooltip>
                  <a-divider style="margin: 0" />
                  <a-tooltip class="max-icon" placement="left" title="行政咨询">
                    <div @click="openAdministrativeSession" class="action-item b-r-8 flex-center">
                      <tw-icons icon-type="administrative-session" />
                      <div class="action-item-tool-vo-title">行政咨询</div>
                    </div>
                  </a-tooltip>
                  <a-divider style="margin: 0" />
                  <div class="components-back-top-demo-custom">
                    <back-top style="width: 100%;" :visibilityHeight="-10">
                      <a-tooltip class="max-icon" placement="left" title="回顶部">
                        <div style="color: #656A72;position: relative" class="action-item b-r-8 flex-center">
                          <tw-icons icon-type="top" />
                          <div class="action-item-tool-vo-title">回顶部</div>
                        </div>
                      </a-tooltip>
                    </back-top>
                  </div>
                </div>
                <a-avatar @click="goUserCenter" class="avatar cp" :src="userAvatarRef" />
                <span @click="goUserCenter" class="user-info-item cp">
                  <span>{{ this.user.realName }}</span>
                  <span>{{ this.user.username }}</span>
                </span>
                <a-popover class="logout-popover b-r-8" placement="bottomRight">
                  <template slot="content">
                    <!-- <a slot="extra" href="#">more</a> -->
                    <div style="overflow: hidden" class="change-user b-r-8">
                      <a-button class="main-button" style="color: rgba(0, 0, 0, .8)" type="link" @click="handleTanant">切换租户</a-button>
                      <a-divider />
                      <a-button class="main-button" style="color: rgba(0, 0, 0, .8)" type="link" @click="openPassModal">修改密码</a-button>
                      <a-divider />
                      <a-button class="main-button" style="color: rgba(0, 0, 0, .8)" type="link" @click="goUserCenter">个人中心</a-button>
                      <a-divider />
                      <a-button class="main-button" style="color: red" type="link" @click="handleLogout">退出系统</a-button>
                    </div>
                  </template>
                  <a-icon type="down" style="font-size: 14px" class="wos" />
                </a-popover>
              </div>
            </div>
          </a-layout-header>
        </a-affix>
        <a-layout>
          <div style="position: fixed;height: 100%;width:76px;" class="app-main-menu">
            <a-menu style="height: 100%; padding: 10px;background-color: rgba(255, 255, 255, .5);" :selected-keys="$store.state.menuKeys">
              <a-menu-item style="height: 56px; width: 56px; padding: 8px" v-for="row in menuFilter" :key="row.name" @click="() => onMenuClick(row)">
                <div
                  style="justify-content: start"
                  v-if="!row.meta || !row.meta.isItemList"
                  class="menu-item-text flex-center"
                >
                  <tw-icons class="cp" :icon-type="row.meta.iconType" />
                  {{ row.meta.title }}
                </div>
                <MenuItemList :item-type="row.meta.type" v-else :title="row.meta.title" />
              </a-menu-item>
            </a-menu>
          </div>
          <a-layout-content :class="contentClass">
            <router-view v-if="changeTanantStatus" />
          </a-layout-content>
        </a-layout>
        <a-layout-footer class="app-footer">
          <div class="url-main-class flex-center">
            <div class="text-auto-ellipsis" style="padding: 0 16px">
              <span>快捷访问:</span>
              <a
                class="url-item"
                :key="index"
                v-for="(item, index) of friendlyLinks"
                target="_blank"
                :href="item.url"
              >{{ item.name }}</a>
            </div>
            <div class="text-auto-ellipsis">
              <span style="margin-right:20px">湘ICP证2-2-2002007</span>
              <span>拓维信息系统股份有限公司版权所有</span>
            </div>
          </div>
        </a-layout-footer>
      </a-layout>
    </div>
  </a-config-provider>
</template>

<script>
import { Modal, Badge, BackTop, Switch } from 'ant-design-vue'
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import { MENU_KEYS, showMenu } from '@/config/router.config'
import { getTenant, setTenantId, getMailUrl, checkUserProgramInfo, updatePassWord } from '@/api/user'
import MenuItemList from '@/components/MenuItemList'
import { types } from '@twit/utils'
import TwIcons from '@/components/tw-icons'
import { friendlyLinks } from '@/views/home/home-data'
// import { md5 } from './md5'
const helpBtnTopKey = 'helpBtnTopKey'
export default {
  components: { TwIcons, MenuItemList, ABadge: Badge, BackTop },
  data () {
    // 密码校验
    const checkePassword = function (rule, value, callback) {
      if (value) {
        const reg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}/
        if (!reg.test(value)) {
          return callback(new Error('密码格式要求: 长度必须6位，包含大小写和特殊符号'))
        } else {
          return Promise.resolve()
        }
      } else {
        return callback(new Error('请输入密码'))
      }
    }
    // 二次确认 let validatePass = (rule, value, callback)
    const againPassword = (rule, value, callback) => {
      if (value !== this.formData.newPassWord) {
        return callback(new Error('密码不一致'))
      }
      if (!value) {
        return callback(new Error('请输入确认密码'))
      }
      return callback()
    }
    return {
      friendlyLinks: friendlyLinks,
      addressUrl: '/api/business-center/v1/users/export/address/book', // 地址簿下载地址
      changeTanantStatus: true,
      tanantVisible: false,
      passWordVisible: false,
      disabledUserSearch: true, // 禁用通讯录搜索
      tanantArr: [],
      contentClass: '',
      queryFuzzy: null, // 搜索框
      // user: {},
      defaultImg: null,
      defaultImg1: require('@/assets/user1.png'),
      defaultImg2: require('@/assets/user2.png'),
      blueBg: require('@/assets/card-bg/blue-bg.png'),
      showAffix: false, // 固钉是否显示
      clearSetTimeOut: null,
      logoStyle: { backgroundImage: 'url()' },
      helpBtnTop: '160px',
      userInputSearch: false,
      oldSystem: [{
        name: '企业邮箱',
        url: 'https://exmail.qq.com/cgi-bin/loginpage'
      }, {
        name: '假勤管理',
        url: 'http://it.talkweb.com.cn/kaoqin'
      }, {
        name: '绩效管理',
        url: 'http://it.talkweb.com.cn/hrkpi'
      }, {
        name: '合同管理',
        url: 'http://cml.talkweb.com.cn/bpmspa/#/home/dashboard'
      }, {
        name: '教育可视化业务',
        url: 'http://vbmp.talkedu.cn'
      }, {
        name: '蓝海学院',
        url: 'http://lhxy.yunxuetang.cn'
      }, {
        name: '财务在线2.0(备查)',
        url: 'http://139.159.184.80:18081'
      }, {
        name: '金蝶EAS8.6',
        url: 'http://eassso.talkweb.com.cn'
      }, {
        name: '电子资质管理',
        url: 'http://oa.talkweb.com.cn/twbpm/r?wf_num=CertificateBorrow'
      }],
      formData: {
        oldPassWord: '',
        newPassWord: '',
        againPassWord: ''
      },
      rules: {
        oldPassWord: [{ required: true, message: '请输入旧密码', trigger: ['blur', 'change'] }],
        newPassWord: [{ required: true, validator: checkePassword, trigger: ['blur', 'change'] }],
        againPassWord: [{ required: true, validator: againPassword, trigger: ['blur', 'change'] }]
      },
      isHomePage: true
    }
  },
  computed: {
    Switch() {
      return Switch
    },
    locale () {
      // 只是为了切换语言时，更新标题
      const { title } = this.$router.meta || ''
      title && setDocumentTitle(`${i18nRender(title)} - ${domTitle}`)
      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
      //  return {}
    },
    menuFilter () {
      // const menus = showMenu()
      // if (this.disabledUserSearch) {
      //   const idx = menus.findIndex((item) => (item.name === MENU_KEYS.ADDRESS_LIST))
      //   menus.splice(idx, 1)
      // }
      return showMenu()
    },
    user () {
      return this.$store.getters.userInfo
    },
    userInfoDetail () {
      return this.$store.getters.userInfoDetail
    },
    msgTotal () {
      return this.$store.getters.noReadMsgCount
    },
    userAvatarRef () {
      const detail = this.$store.getters.userInfoDetail
      if (!detail.userPicture) {
        return this.$utils.getDefaultAvatar(detail.sex)
      }
      return `/api/file-server/file/download?fileId=${detail.userPicture}`
    }
  },
  async created () {
    this.setContentClass()
    // await this.initUser()
    // await this.initTenant()
    await this.$utils.initMenuKey()
    // await this.queryUserId()
    await this.changeFontSize()
    // 删除第一次进首页时的URL里面的SESSION
    if (window.location.href.indexOf('SESSION') !== -1) {
      this.$router.push({
        path: '/home/index'
      })
    }
  },
  watch: {
    // 保证顶部菜单定位
    '$route.path': {
      handler () {
        this.setContentClass()
        this.isHomePage = (this.$route.path.lastIndexOf('home/index') > 0) // 是否为主页
        if (window.location.href.indexOf(MENU_KEYS.HOME) !== -1) {
          this.$utils.setMenuKey(MENU_KEYS.HOME)
        }
        if (window.location.href.indexOf(MENU_KEYS.APP_CENTER) !== -1) {
          this.$utils.setMenuKey(MENU_KEYS.APP_CENTER)
        }
        if (window.location.href.indexOf(MENU_KEYS.USER_CENTER) !== -1) {
          this.$utils.setMenuKey(MENU_KEYS.USER_CENTER)
        }
        // 如果path中出现两次'/app-center',有可能是静态资源进行发布后,用户浏览器没有进行刷新
        // 前端乾坤缓存了index.html页面后没有及时更新到最新的静态资源,导致出现空白'功能'标签页
        // 这里直接刷新整个窗口
        if (this.$route.path.lastIndexOf('/app-center') > 0) {
          const url = '/app-center/menus?resourceCode=' + sessionStorage.getItem('firstAppName')
          // this.$router.replace(sessionStorage.getItem('lastClickPaneUrl') || url)
          window.location.replace('/pba' + (sessionStorage.getItem('lastClickPaneUrl') || url))
          setTimeout(() => {
            window.location.reload()
          }, 100)
        }
        const top = localStorage.getItem(helpBtnTopKey)
        if (top) {
          this.helpBtnTop = top
        }
      }
    },
    user (val) {
      if (val) {
        this.$bus.$emit('userInfoChange', val)
        this.initUser()
      }
    }
  },
  methods: {
    toSearchUser (sign) {
      this.$utils.setMenuKey(MENU_KEYS.ADDRESS_LIST)
      this.$router.push({ path: '/address-list', query: { sign } })
      this.userInputSearch = false
    },
    async initTenant () {
      const { data } = await getTenant(this.user.uid)
      this.tanantArr = data
      this.setLogoStyle()
    },
    setLogoStyle () {
      const style = { backgroundImage: 'url()' }
      if (this.tanantArr.length) {
        style.backgroundImage = 'url(' + this.tanantArr[0].logo + ')'
      }
      this.logoStyle = style
    },
    triggerUserInputSearch () {
      const btn = document.querySelector('#page-action-btn-slider')
      const isDrag = btn && btn.getAttribute('isDrag')
      if (isDrag === 'true') {
        return
      }
      this.userInputSearch = !this.userInputSearch
      const top = localStorage.getItem(helpBtnTopKey)
      if (top) {
        this.helpBtnTop = top
      }
    },
    changeTanant (item) {
      // item.name}  |  ${item.description
      const _this = this
      this.$confirm({
        title: `切换到租户 ${item.name} `,
        content: item.description,
        async onOk () {
          const { code } = await setTenantId({ tenantId: item.id })
          if (code === 0) {
            // storage.set('tenantId', item.id)
            localStorage.setItem('tenantId', item.id)
            _this.$utils.setIsClearPanes(true) // 切换租户清空多开
            const temporarinessArr = _this.tanantArr.filter((_) => {
              return _.id !== item.id
            })
            temporarinessArr.unshift(item)
            // this.$router.push('refresh')
            _this.tanantArr = temporarinessArr
            _this.changeTanantStatus = false
            _this.$nextTick(() => {
              _this.changeTanantStatus = true
              _this.tanantVisible = false
            })
            _this.setLogoStyle()
          }
        },
        onCancel () {
          console.log('Cancel')
        },
        class: 'test'
      })
    },
    handleTanant () {
      this.tanantVisible = true
    },
    handleTanantOk () { },
    openPassModal () {
      this.passWordVisible = true
    },
    cancelPassWord () {
      this.formData = {
        oldPassWord: '',
        newPassWord: '',
        againPassWord: ''
      }
      this.$refs.ruleForm.clearValidate()
      this.passWordVisible = false
      this.$emit('input', false)
    },
    handlePassWord () {
      const obj = {
        id: localStorage.getItem('userID'),
        newPassword: this.$md5(this.formData.newPassWord),
        password: this.$md5(this.formData.oldPassWord)
      }
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const { code } = await updatePassWord(obj)
        //   const res = await this.$ajax({
        //   url: '/wutong-idaas-account/user/update-password',
        //   method: 'post',
        //   data: obj
        // })
        if (Number(code) === 0) {
          debugger
          this.formData = {
            oldPassWord: '',
            newPassWord: '',
            againPassWord: ''
          }
            this.$message.success('修改成功!')
            this.passWordVisible = false
            this.$emit('input', false)
          }
        }
      })
    },
    setContentClass () {
      this.contentClass = 'my-parent-content-home'
      if (window.location.href.indexOf(MENU_KEYS.HOME) < 0) {
        this.contentClass = 'my-parent-content-app'
      }
    },
    openHrSession () {
      window.open('https://work.weixin.qq.com/nl/innerkfid/ikf7InKCgAAr9YeFdp_ZFmFBeKYC0pq4w', '_top')
    },
    openAdministrativeSession () {
      window.open('https://work.weixin.qq.com/nl/innerkfid/ikf7InKCgAAtvke5j4CvlNnMeY12dIJjA', '_top')
    },
    openQuestion () {
      const btn = document.querySelector('#page-action-btn-slider')
      const isDrag = btn && btn.getAttribute('isDrag')
      if (isDrag === 'true') {
        return
      }
      // this.$router.push('/app-center/main/WOS/interfaces/processform?key=Process_WOS_WOH')
      const iWidth = 1100 // 弹出窗口的宽度;
      const iHeight = 500 // 弹出窗口的高度;
      const iTop = (window.screen.height - iHeight) / 2 // 获得窗口的垂直位置;
      const iLeft = (window.screen.width - iWidth) / 2 // 获得窗口的水平位置;

      const url = '/WOS/interfaces/processform?key=Process_WOS_WOH'
      this.$utils.openWin(
        url,
        '问题反馈',
        'height=' + iHeight + ',width=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',scrollbars=yes,resizable=yes'
      )
    },
    async initUser () {
      this.$utils.setItem('userCode', this.user.uid)
      if (this.$utils.isEmpty(this.user.avatar)) {
        this.defaultImg = this.defaultImg1
      } else {
        this.defaultImg = this.user.avatar
      }
      await this.initTenant()
      checkUserProgramInfo(this.user.uid).then((data) => {
        const tsmp = data.tsmp || {}
        this.disabledUserSearch = !tsmp.visible
      })
      this.$store.dispatch('updateNoReadMsgCount')
      this.$store.dispatch('getUserDetail')
    },
    // 页面导航部分
    onSearch () {
      if (!this.queryFuzzy) { return }
      this.$router.push({ path: '/app-center/menus', query: { resourceCode: 'search', queryFuzzy: this.queryFuzzy } })
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    handleLogout (e) {
      Modal.confirm({
        title: this.$t('即将退出系统'),
        content: this.$t('是否确认退出?'),
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          this.$utils.setMenuKey(MENU_KEYS.HOME)
          document.cookie = 'SESSION=;path=/'
          localStorage.clear()
          sessionStorage.clear()
          window.open('/idaas/login', '_self')
          // window.location.href = 'http://cas.talkweb.com.cn/cas/logout?service=https%3A%2F%2Fit.talkweb.com.cn'
        },
        onCancel () { }
      })
    },
    onMenuClick (row) {
      if (row.url) {
        if (row.url === '/wiki-index') {
          return this.openWiki()
        }
        return this.oldSystemClick(row.url)
      }
      if (row.meta && row.meta.isItemList) {
        return
      }
      this.$utils.setMenuKey(row.name)
      let query = {}
      if (row.path === '/app-center') {
        // 如果firstAppName为空,则不给resourceCode赋默认值,因为左侧菜单为权限控制,不同的人菜单内容不一样
        // 在appCenter/index.vue中会根据接口返回的权限集合默认选择第一条数据设置firstAppName
        // 在AppCenter/Menus.vue中会使用firstAppName来获取子应用列表
        query = { resourceCode: sessionStorage.getItem('firstAppName') || '' }
      }
      this.$router.push({ path: row.path, query })
    },
    async openWiki () {
      const userCode = localStorage.getItem('userCode')
      const res = await this.$ajax({
        url: '../wiki-index/developers-server/rest/user/req-gateway/code-login',
        method: 'post',
        data: {
          userCode: userCode
        }
      })
      if (res.code === 0) {
        const tk = !types.isEmpty(res.data.token) ? res.data.token : ''
        const url = `/wiki-index?_tk=${tk}`
        window.open(url, '_blank')
      }
    },
    oldSystemClick (url) {
      window.open(url)
    },
    async toMail () {
      const res = await getMailUrl()
      if (res.code === 0) {
        const url = res.data
        window.open(url)
      }
      //   this.$utils.setMenuKey(MENU_KEYS.USER_CENTER)
      //   this.$router.push('/user-center/mail')
    },
    isMouse () {
      this.showAffix = true
    },
    noMouse () {
      clearTimeout(this.clearSetTimeOut)
      this.clearSetTimeOut = setTimeout(() => {
        this.showAffix = false
      }, 2000)
    },
    changeFontSize () {
      const obj = document.getElementById('search')
      obj.style.fontSize = '12px'
      obj.style.height = '30px'
    },
    goMsgCenter () {
      this.$router.push('/user-center/message')
    },
    goUserCenter () {
      this.$router.push('/user-center')
    },
    toFullScreen () {
      this.$utils.toggleFullScreen()
    }
  },
  mounted () {
    const top = localStorage.getItem(helpBtnTopKey)
    if (top) {
      this.helpBtnTop = top
    }
    if (this.user && this.user.uid) {
      this.$bus.$emit('userInfoChange', this.user)
    }
    window.addEventListener('click', () => {
     this.userInputSearch = false
    })
  },
  directives: {
    drag: {
      inserted (el) {
        let firstTime = null
        let lastTime = null
        el.onmousedown = function (ev) {
          firstTime = new Date().getTime()
          const disY = ev.clientY - el.offsetTop
          document.onmousemove = function (ev) {
            const t = Math.min(Math.max(ev.clientY - disY, 65), document.body.clientHeight - 300)
            el.style.top = t + 'px'
          }
          document.onmouseup = function (ev) {
            const t = Math.min(Math.max(ev.clientY - disY, 65), document.body.clientHeight - 300)
            const currentTop = t + 'px'
            localStorage.setItem(helpBtnTopKey, currentTop)
            this.helpBtnTop = currentTop
            lastTime = new Date().getTime()
            // 鼠标抬起时，销毁移动事件和鼠标抬起事件
            document.onmousemove = null
            document.onmouseup = null
            if ((lastTime - firstTime) > 200) {
              el.setAttribute('isDrag', true)
            } else {
              el.setAttribute('isDrag', false)
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 12px;
  margin-bottom: 15px;
}

.app-main {
  min-height: 100vh;
  ::v-deep .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    margin-right: 0!important;
  }
  .logo {
    width: 30%;
    height: 100%;
    display: inline-block;
    .logo-img {
      cursor: pointer;
      background-image: url(~@/assets/new_logo.png);
      background-position: left center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 50%;
      display: inline-block;
      max-height:100%;
      height: 60px;
    }
    .menus {
      width: 50%;
      vertical-align:baseline;
      padding-left: 10px;
      position: relative;
      float: right;
      color: @primary-color;
    }
  }
  .title {
    font-size: 20px;
    float: left;
    // padding-top: 3px;
    color: #fff;
    margin: 0;
  }
  .avatar {
    margin-bottom: 4px;
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
  .search {
    width: 280px;
    height: 30px;
    font-size: 12px;
    margin-right: 17px;
    ::v-deep input {
      border-radius: 4px;
      background-color: #F2F3F5;
      border: none;
    }
  }
  .logout {
    font-size: 20px;
    color: #343030;
  }
  .logout-popover {
    position: relative;
  }
  .apps-wrap {
    float: right;
    margin-top: 3px;
    .icon {
      font-size: 20px;
      color: #fff;
    }
  }
  ::v-deep .ant-affix {
    z-index: 100;
  }
}
.apps-list {
  .item {
    margin: 10px auto;
    a {
      display: block;
      margin: 0 auto 8px;
    }
    .affix-img {
      display: block;
      margin: 0 auto;
    }
    .name {
      font-size: 12px;
      text-align: center;
      display: block;
    }
  }
}
.ant-layout-header {
  height: 60px;
  padding: 0 1%;
  line-height: 60px;
  background-color: white;
  border-bottom: 1px solid #ebebeb;
}
//.ant-input-search {
//  font-size: 12px;
//  height: 30px;
//  border-radius: 5px;
//}

.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  height: 100%;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  //color: #2A3F98;
}

.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  height: 100%;
  display: inline-block;
}

.home-page-sub-menu {
  ::v-deep .ant-menu-submenu-title {
    display: inline-block;
    padding: 0;
    //margin-left: 0;
    align-items: center;
    width: 100%;
    height: 100%;
   .ant-menu-submenu-arrow{
      display: none;
    }
  }
}

.ant-menu-item {
  height: 30px;
  line-height: 30px;
  padding: 2px 5px;
  //color: black!important;
  text-align: start;
  border-radius: 8px;
  align-items: start;
  .menu-item-text {
    flex-direction: column;
    border-radius: 5px;
    height: 100%;
    font-size: 12px;
  }
}

.ant-menu-item {
  border-bottom: 0 solid transparent !important;
}

.ant-menu-item.ant-menu-item-selected,
.ant-menu-item:hover {
  color: @primary-color;
  background-color: white!important;
  box-shadow: 0 0 10px 0 rgba(0, 56, 112, 0.06);
  border-bottom: 0 solid @primary-color!important;
}

::v-deep .ant-menu-overflowed-submenu {
  border-bottom: 2px solid transparent !important;
}

::v-deep .ant-menu-overflowed-submenu:hover {
  border-bottom: 2px solid @primary-color!important;
}
.ant-layout-sider {
  background: #fff;
}
.hidden {
  display: none;
}
.global-min-width {
  //min-width: 100%;
  min-height: 100%;
}
.my-parent-content-home {
  margin-left: 76px!important;
}
.my-parent-content-app {
  // padding: 10px 0px 5px 0px;
  // margin-top: 10px;
  margin-left: 76px!important;
  padding-right: 20px;
}
.my-content {
  min-height: calc(100vh - 478px);
}
.no-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
  max-width: 100%;
}
// ::v-deep .ant-btn {
//   span {
//     .no-wrap;
//   }
// }
::v-deep .ant-modal-title {
  font-size: 16px;
}
::v-deep .main-button {
  span {
    .no-wrap;
  }
}

.change-user {
  font-size: 14px;
  ::v-deep .ant-divider-horizontal {
    margin: 5px 0;
    color: rgba(0, 0, 0, .8);
    border-bottom: none;
  }
  ::v-deep .main-button {
    border-radius: 8px;
  }
  ::v-deep .main-button:hover {
    background-color: #EDEEEE;
  }
}
.ant-menu-item,
.ant-menu-submenu {
  color: rgba(0, 0, 0, 0.65);
  padding: 0 8px;
}
.right-wrap {
  float: right;
  .search {
    display: inline-block;
    vertical-align: middle;
  }
  .badge {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}
.wos {
  font-size: 18px;
  padding: 0 7px;
  cursor: pointer;
  color: #706e6e;
  // float: right;
  display: inline-block;
  vertical-align: middle;
}
.user-info-item {
  font-size: 12px;
  color: black;
  height: 100%;
  line-height: 100%;
  margin-left: 5px;
  overflow: hidden;
  min-width: 90px;
  // float: right;
  display: inline-block;
  vertical-align: middle;
  // width: 200px;
  // vertical-align: middle;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  span {
    height: 18px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    // width: 100%;
    margin: 0 4px;
    text-overflow: ellipsis;
  }
}
.max-icon:active {
  border: none;
}

.page-action-btn {
  border-radius: 12px;
  color: white;
  padding: 5px 5px;
  font-size: 14px;
  background: linear-gradient(175deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.86) 86%);
  box-sizing: border-box;
  border-image: linear-gradient(347deg, #D3DBDE 0%, rgba(255, 255, 255, 0) 21%) 1;
  backdrop-filter: blur(3px);
  box-shadow: 0 0 12px 0 rgba(0, 77, 155, 0.2);
  .text-tips {
    display: inline-block;
  }
  .action-item {
    background-color: white;
    margin-bottom: 10px;
    flex-direction: column;
    cursor: pointer;
    padding-top: 10px;
    .action-item-tool-vo-title {
      text-align: center;
      height: 20px;
      line-height: 20px;
      width: 100%;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 4px;
      overflow: hidden;
    }
  }
  .action-item:hover {
    color: @primary-color!important;
  }
  .action-item.active {
    color: @primary-color!important;
  }
  .tong-xun-lu-input {
    position: absolute;
    padding-right: 50px;
    right: 24px;
    top: 5px;
    width: 365px;
    height: 48px;
    ::v-deep .ant-input-search input {
      height: 40px;
      border-radius: 8px;
      box-shadow: 0 0 10px 0 rgba(0, 56, 112, 0.2);
      border: none;
    }
  }
  .tong-xun-lu-input::after{
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-left-color: white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 38px;
  }
}
.app-footer {
  z-index: 1;
  background-color: #E9EBEE;
  padding: 8px 0;
  .url-main-class {
    width: 88%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 0, 0, 0.65);
    >div {
      line-height: 35px;
      height: 35px;
    }
  }
  .url-item {
    color: rgba(0, 0, 0, 0.65);
    padding: 12px;
    font-weight: bold;
    text-decoration: none;
    position: relative;
  }
  .url-item:hover {
    color: #0080ff;
  }
  .url-item::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 14px;
    background-color: #ccc;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .url-item:last-child::after {
    display: none;
  }
}
.components-back-top-demo-custom {
  height: 48px;
  .ant-back-top {
    position: relative;
    top: 0;
    right: 0;
  }
}
</style>
