<template>
  <div>
    <a-layout id="components-layout-demo-top" class="layout">
      <a-layout-header style="background: #fff">
        <div class="logo"></div>
        <a-menu
          mode="horizontal"
          :default-selected-keys="defaultKeys"
          :style="{ lineHeight: '64px' }"
        >
          <a-menu-item v-for="(row) in menus" :key="row.key">
            <router-link :to="row.url">
              {{ row.title }}
            </router-link>
          </a-menu-item>
        </a-menu>
      </a-layout-header>
      <a-layout-content style="padding: 15px 50px 0px 50px">
        <div id="micro-view"></div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        footer
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      menus: [{
        key: 'PBC',
        title: '控制中心',
        url: '/business-center'
      },
      {
        key: 'PBC1',
        title: '控制中心1',
        url: ''
      }],
      defaultKeys: []
    }
  },
  computed: {
    ...mapState({
      mainMenu: state => state.permission.addRouters
    })
  },
  created () {
    if (this.menus.length > 0) {
      this.defaultKeys = [this.menus[0].key]
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
// #components-layout-demo-top .logo {
//   width: 160px;
//   height: 31px;
//   background: url(~@/assets/index_logo.png) no-repeat;
//   margin: 16px 24px 16px 0;
//   float: left;
// }
</style>
