import request from '@/utils/request'

const uniqueNo = localStorage.getItem('uniqueNo')

const Api = {
  listApps: '/wuyou-auth/auth/list-old-apps',
  listMenus: '/wuyou-auth/auth/list-app-menus',
  listModules: '/wuyou-auth/auth/list-app-modules',
  listServices: '/wuyou-auth/auth/list-app-services',
  listCommonUse: '/wuyou-auth/common-use/list',
  addCommonUse: '/wuyou-auth/common-use/add',
  deleteCommonUse: '/wuyou-auth/common-use/delete'
}

export function listAllResource (appCodes) {
  return request({
    url: Api.listMenus,
    method: 'post',
    data: {
      userId: uniqueNo,
      appCodes: appCodes
    }
  })
}

export async function listApps () {
  const { data } = await request({
    url: Api.listApps,
    method: 'post',
    data: {
      userId: uniqueNo
    }
  })
  return new Promise((resolve) => {
    const array = data === undefined || data === null ? [] : data
    const apps = []
    array.forEach(item => {
      apps.push({
        resourceName: item.moduleName,
        resourceCode: item.moduleCode,
        systemCode: item.moduleCode,
        moduleCode: item.moduleCode,
        appCodes: item.appCodes
      })
    })
    resolve(apps)
  })
}

export function getLoginUser () {
  return request({
    url: Api.getLoginUser,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function listMenus (params) {
  return request({
    url: Api.listMenus,
    method: 'post',
    data: {
      userId: uniqueNo,
      appCodes: params.appCodes,
      queryFuzzy: params.queryFuzzy
    }
  })
}

export function listServices (params) {
  return request({
    url: Api.listServices,
    method: 'post',
    data: {
      userId: uniqueNo,
      appCodes: params.appCodes,
      queryFuzzy: params.queryFuzzy
    }
  })
}

export function listModules (params) {
  return request({
    url: Api.listModules,
    method: 'post',
    data: {
      userId: uniqueNo,
      appCodes: params.appCodes
    }
  })
}

export function listCommonUse () {
  return request({
    url: Api.listCommonUse,
    method: 'get'
  })
}

export function addCommonUse (resourceCode) {
  return request({
    url: Api.addCommonUse,
    method: 'post',
    data: { resourceCode }
  })
}

export function deleteCommonUse (resourceCode) {
  return request({
    url: Api.deleteCommonUse,
    method: 'post',
    data: { resourceCode }
  })
}
