import {adduserrecentvisitedres} from "@/api/user";

const recentAppRankCacheKey = 'RECENT_APP_RANK_CACHE_KEY'
const colorMap = [
  ['#C0D8FE', '#2A4098'],
  ['#FFDEE5', '#FD898E'],
  ['#C8F5D1', '#2A9841'],
  ['#FEEBD7', '#FF943D'],
  ['#C7BFF2', '#6D15AD'],
  ['#EDB1F9', '#DF22EA'],
  ['#FFFDDE', '#F5B400'],
  ['#DEF6FF', '#0BC7F9']
]
const bigClassMap = {
  HR: {
    name: '人力资源',
    bg: colorMap[0][0],
    color: colorMap[0][1]
  },
  BMS: {
    name: '运营管理',
    bg: colorMap[1][0],
    color: colorMap[1][1]
  },
  PMS: {
    name: '项目管理',
    bg: colorMap[2][0],
    color: colorMap[2][1]
  },
  SRM: {
    name: '供应链管理',
    bg: colorMap[3][0],
    color: colorMap[3][1]
  },
  FOL: {
    name: '财务管理',
    bg: colorMap[4][0],
    color: colorMap[4][1]
  },
  PBC: {
    name: '控制中心',
    bg: colorMap[5][0],
    color: colorMap[5][1]
  },
  ADMINS: {
    name: '行政综合',
    bg: colorMap[6][0],
    color: colorMap[6][1]
  },
  OFFICIAL: {
    name: '官网管理',
    bg: colorMap[7][0],
    color: colorMap[7][1]
  },
  BRAND: {
    name: '品牌营销',
    bg: colorMap[0][0],
    color: colorMap[0][1]
  },
  REPORTS: {
    name: '报表管理',
    bg: colorMap[1][0],
    color: colorMap[1][1]
  },
  WHS: {
    name: '仓库管理',
    bg: colorMap[2][0],
    color: colorMap[2][1]
  },
  WPASS: {
    name: '智慧园区运营管理',
    bg: colorMap[3][0],
    color: colorMap[3][1]
  },
  WOS: {
    name: 'IT管理',
    bg: colorMap[4][0],
    color: colorMap[4][1]
  },
  USERCENTER: {
    name: '用户中心',
    bg: colorMap[1][0],
    color: colorMap[1][1]
  }
}

export const getShowConfigByBigClass = (systemCode) => {
  return bigClassMap[systemCode] || {
    name: '',
    bg: colorMap[4][0],
    color: colorMap[4][1]
  }
}

function getCacheObjByKey (key) {
  let obj = {}
  const strCache = localStorage.getItem(key)
  if (strCache && typeof strCache === 'string') {
    try {
      obj = JSON.parse(strCache)
    } catch (e) {
      console.log(e)
    }
  }
  return obj
}
export function setRecentAppCache (app) {
  const {
    resourceName,
    resourceCode,
    moduleCode = app.frontModuleCode,
    resourcePath,
    systemCode = 'null',
    iconSrc,
    src
  } = app

  const appInfo = { src, resourceName, resourceCode, resourcePath, moduleCode, systemCode, iconSrc } // 项目必要信息
  adduserrecentvisitedres(appInfo).then(() => console.log('add user recent app success'))
}

export function getTopAppRankByLen (len) {
  const recentAppRank = getCacheObjByKey(recentAppRankCacheKey)
  const keys = Object.keys(recentAppRank) || []
  const times = []
  const top = []
  keys.forEach((key) => {
    times.push(recentAppRank[key].time)
  })
  times.sort((a, b) => (b - a))
  times.length = len
  while (times.length) {
    const time = times.shift()
    keys.forEach((key) => {
      if (time === recentAppRank[key].time) {
        top.push(recentAppRank[key].app)
      }
    })
  }
  return top
}
