import store from '@/store'
class Utils {
  timeFix () {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
  }

  welcome () {
    const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
    const index = Math.floor(Math.random() * arr.length)
    return arr[index]
  }

  /**
   * 触发 window.resize
   */
  triggerWindowResizeEvent () {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
  }

  handleScrollHeader (callback) {
    let timer = 0

    let beforeScrollTop = window.pageYOffset
    callback = callback || function () { }
    window.addEventListener(
      'scroll',
      event => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          let direction = 'up'
          const afterScrollTop = window.pageYOffset
          const delta = afterScrollTop - beforeScrollTop
          if (delta === 0) {
            return false
          }
          direction = delta > 0 ? 'down' : 'up'
          callback(direction)
          beforeScrollTop = afterScrollTop
        }, 50)
      },
      false
    )
  }

  openWin (url, title, winInfo, onClose) {
    if (!url) return
    if (!url.includes('SESSION')) {
      url += (url.includes('?') ? '&' : '?') + 'SESSION=' + sessionStorage.getItem('SESSION')
    }
    var winObj = window.open(url, title, winInfo)
    if (winObj === null || winObj === undefined) {
      return
    }
    var loop = setInterval(function () {
      if (winObj.closed) {
        clearInterval(loop)
        if (onClose !== null && onClose !== undefined) {
          onClose()
        }
      }
    }, 1)
  }

  isIE () {
    const bw = window.navigator.userAgent
    const compare = (s) => bw.indexOf(s) >= 0
    const ie11 = (() => 'ActiveXObject' in window)()
    return compare('MSIE') || ie11
  }

  /**
   * Remove loading animate
   * @param id parent element id or class
   * @param timeout
   */
  removeLoadingAnimate (id = '', timeout = 1500) {
    if (id === '') {
      return
    }
    setTimeout(() => {
      document.body.removeChild(document.getElementById(id))
    }, timeout)
  }

  initTreeData (nodes = [], key, pKey) {
    const treeNodes = []
    for (const menu1 of nodes) {
      const children = []
      for (const menu2 of nodes) {
        if (menu1[key] + '' === menu2[pKey] + '') {
          menu2._parentNode = menu1
          children.push(menu2)
        }
      }
      if (children.length > 0) {
        menu1.children = children
      }
      treeNodes.push(menu1)
    }

    const result = []
    for (const menu1 of treeNodes) {
      var isRoot = true
      for (const menu2 of treeNodes) {
        if (menu1[pKey] + '' === menu2[key] + '') {
          isRoot = false
          break
        }
      }
      if (isRoot) {
        result.push(menu1)
      }
    }
    return result
  }

  deepClone (o) {
    // 判断如果不是引用类型，直接返回数据即可
    if (typeof o === 'string' || typeof o === 'number' || typeof o === 'boolean' || typeof o === 'undefined') {
      return o
    } else if (Array.isArray(o)) { // 如果是数组，则定义一个新数组，完成复制后返回
      // 注意，这里判断数组不能用typeof，因为typeof Array 返回的是object
      var _arr = []
      o.forEach(item => { _arr.push(item) })
      return _arr
    } else if (typeof o === 'object') {
      var _o = {}
      for (const key in o) {
        _o[key] = this.deepClone(o[key])
      }
      return _o
    }
  }

  isEmpty (data) {
    return this.isNull(data) || this.isUndefined(data) || data === ''
  }
  isArray (data) {
    return Object.prototype.toString.call(data) === '[object Array]'
  }
  isString (data) {
    return Object.prototype.toString.call(data) === '[object String]'
  }
  isNumber (data) {
    return Object.prototype.toString.call(data) === '[object Number]'
  }
  isBoolean (data) {
    return Object.prototype.toString.call(data) === '[object Boolean]'
  }
  isNull (data) {
    return Object.prototype.toString.call(data) === '[object Null]'
  }
  isEmptyObject (obj) {
    return Object.keys(obj).length === 0
  }
  isUndefined (data) {
    return Object.prototype.toString.call(data) === '[object Undefined]'
  }
  isFunction (data) {
    return Object.prototype.toString.call(data) === '[object Function]'
  }
  isObject (data) {
    return Object.prototype.toString.call(data) === '[object Object]'
  }
  isDate (data) {
    return Object.prototype.toString.call(data) === '[object Date]'
  }
  isRegExp (data) {
    return Object.prototype.toString.call(data) === '[object RegExp]'
  }
  isSymbol (data) {
    return Object.prototype.toString.call(data) === '[object Symbol]'
  }
  isEmail (email) {
    return /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(email)
  }
  isPhone (phoneNumber) {
    return /^[1][3,4,5,7,8][0-9]{9}$/.test(phoneNumber)
  }
  // 将键值对存入sessionStorage
  setItem (key, value) {
    sessionStorage.setItem(key, value)
  }
  // 取sessionStorage里面参数
  getItem (key) {
    return sessionStorage.getItem(key)
  }

  setMenuKey (key) {
    sessionStorage.setItem('app-menu-key', key)
    store.commit('changeMenuKey', key)
  }

  initMenuKey () {
    const key = sessionStorage.getItem('app-menu-key')
    if (!this.isEmpty(key)) {
      store.commit('changeMenuKey', key)
    }
  }

  setIsClearPanes (key) {
    store.commit('changeIsClearPanes', key)
  }

  getUUID () { // 生成唯一uuid
    let d = new Date().getTime()
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now()
    }
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
  }
  // 全屏切换
  toggleFullScreen () {
    const element = document.documentElement
    // 判断是否已经是全屏
    // 如果是全屏，退出
    if (this.fullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen()
      }
    }
    // 改变当前全屏状态
    this.fullscreen = !this.fullscreen
  }
  toCopy (text) {
    return new Promise((resolve, reject) => {
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        navigator.clipboard.writeText(text).then(() => {
          resolve()
        }).catch((err) => {
          reject(err)
        })
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = text
        textArea.style.height = '0'
        textArea.style.opacity = '0'
        document.body.appendChild(textArea)
        textArea.select()
        // 执行复制命令并移除文本框
        document.execCommand('copy')
        textArea.remove()
        resolve()
      }
    })
  }

  getDefaultAvatar (sex) {
    if (['1', '2'].includes(String(sex))) {
      return require(`@/assets/user-avater${sex}.png`)
    } else {
      return require('@/assets/user1.png')
    }
  }
}
export default new Utils()
